import type { FilterGroupV1, FilterGroupV1Option } from '@/features/filter/filter-data-v1-type';

import clone from '@/core/utils/clone';

type ToggleFilterActionPayload = {
    groupName: string;
    isDeactivated: boolean;
    isDefault: boolean;
    isMultiSelect?: boolean;
    isQuickFilterDeselect?: boolean;
    isSelected: boolean;
    isSingleSelect?: boolean;
    optionNames: string[];
    type: 'deselect' | 'reset' | 'select';
};

export type ToggleFilterActionType = (payload: ToggleFilterActionPayload) => void;

export const toggleFilterInFilterAttributes = (
    filterGroups: FilterGroupV1[],
    payload: ToggleFilterActionPayload,
): FilterGroupV1[] => {
    return filterGroups.map((group) => {
        if (group.name !== payload.groupName) {
            return {
                ...group,
                groups: group.groups ? toggleFilterInFilterAttributes(group.groups, payload) : [],
            };
        }
        return {
            ...group,
            groups: group.groups ? toggleFilterInFilterAttributes(group.groups, payload) : [],
            options: getUpdatedFilterGroupOption(group.options, payload),
        };
    });
};

export const getUpdatedFilterGroupOption = (options: FilterGroupV1Option[], payload: ToggleFilterActionPayload) => {
    const { isMultiSelect = false, isSingleSelect, optionNames } = payload;
    const isDefaultSelected = options.some((option) => option.isDefault && optionNames.includes(option.name));

    // In Single-Select mode
    if (isSingleSelect) {
        return clone(options).map((option) => {
            /* eslint-disable fp/no-mutation */
            if (optionNames.includes(option.name)) {
                option.isSelected = !option.isSelected;
            } else {
                option.isSelected = false;
            }

            return option;
        });
    }

    // In Multi-Select mode
    return clone(options).map((option) => {
        /* eslint-disable fp/no-mutation */

        if (!isMultiSelect || option.isDefault || isDefaultSelected) {
            option.isSelected = false;
        }

        if (optionNames.includes(option.name)) {
            option.isSelected = !option.isSelected;
        }

        return option;
    });
};

export const resetFilterAttributes = (filterGroups: FilterGroupV1[]): FilterGroupV1[] => {
    return filterGroups.map((group) => {
        if (!group.isResettable) {
            return group;
        }
        return {
            ...group,
            groups: group.groups ? resetFilterAttributes(group.groups) : [],
            options: group.options.map((option) => {
                if (group.type !== 'single-mandatory' && option.isSelected) {
                    return { ...option, isSelected: false };
                }
                return option;
            }),
        };
    });
};

export const createTogglePayloadObject = (
    filterGroup: FilterGroupV1,
    option: FilterGroupV1Option,
): ToggleFilterActionPayload => {
    return {
        groupName: filterGroup.name,
        isDeactivated: option.count === 0,
        isDefault: option.isDefault,
        isMultiSelect: filterGroup.type === 'multiple',
        isSelected: option.isSelected,
        isSingleSelect: filterGroup.type === 'single',
        optionNames: [option.name],
        type: option.isSelected ? 'deselect' : 'select',
    };
};
