import type { ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { OverlayHistoryState } from '@/core/features/modals/overlay-history/overlay-history-state';
import type { ComponentSize } from '@/features/map/map-container/use-map-splitscreen-size';
import type { IMapTile } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { useDynamicPageQueryFetching } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { clientUrlAtom, layoutIdAtom } from '@/core/features/app/app-atoms';
import { overlayHistoryStateRecordAtom } from '@/core/features/modals/overlay-history/overlay-history-state';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { getMapOverlayKey, isMapDataHistoryState } from '@/features/map/map-container/use-map-overlay-history';
import {
    isMapDataV5ByBoundariesLoadingAtom,
    isMapDataV5BySpotLoadingAtom,
    isMapDataV5DefaultLoadingAtom,
    mapDataV5ByPoiAtom,
    mapDataV5DefaultAtom,
    mapDataV5EntryTargetAtom,
    multiPinsAtom,
    useLoadMoreButtonLoading,
} from '@/features/map/map-data-v5/use-map-data-v5';
import { useTravelFormSubmitLoading } from '@/features/travel-form/travel-form-state';

export const getIsMapSearchLayout = (layoutId?: ILayoutId | null) => layoutId?.includes('Map_Search');

export const mapScreenSizeAtom = atom<ComponentSize | null>(null);

export const isGoogleMapInitializedAtom = atom(false);

const mapVisitedPoisAtom = atom<string[]>([]);
export const useMapVisitedPois = () => useAtom(mapVisitedPoisAtom);

const isLayerSwitchOverlayOpenAtom = atom(false);
export const useLayerSwitchOverlayOpen = () => useAtom(isLayerSwitchOverlayOpenAtom);

const isMapTravelFormLoadingAtom = atom(false);
export const useMapTravelFormLoading = () => useAtomValue(isMapTravelFormLoadingAtom);

export const overlayHistoryCurrentMapStateAtom = atom<OverlayHistoryState | null>((get) => {
    const layoutId = get(layoutIdAtom);
    const clientUrl = get(clientUrlAtom);
    return get(overlayHistoryStateRecordAtom)[`${getMapOverlayKey(clientUrl, { layoutId })}`] ?? null;
});

export const mapTilesStateAtom = atom((get) => {
    const mapDataDefault = get(mapDataV5DefaultAtom);
    const mapDataByPoi = get(mapDataV5ByPoiAtom);

    return mapDataByPoi?.attributes?.tiles ?? mapDataDefault?.attributes?.tiles;
});

const mapSelectedPoiKeyStateAtom = atom<null | string>(null);
export const useSetSelectedPoiKeyState = () => useSetAtom(mapSelectedPoiKeyStateAtom);
const isSelectedMapTileHiddenAtom = atom(false);
export const useSetSelectedMapTileHiddenAtom = () => useSetAtom(isSelectedMapTileHiddenAtom);

export const mapActivePoiKeyStateAtom = atom<null | string>((get) => {
    const selected = get(mapSelectedPoiKeyStateAtom);
    if (selected) {
        return selected;
    }
    const isSelectedMapTileHidden = get(isSelectedMapTileHiddenAtom);
    if (isSelectedMapTileHidden) {
        return null;
    }
    /**
     * return history state if no selected tile is set
     */
    const mapDataV5EntryTarget = get(mapDataV5EntryTargetAtom);
    const overlayHistoryMapState = get(overlayHistoryCurrentMapStateAtom);
    const overlayHistoryMapStateData = isMapDataHistoryState(overlayHistoryMapState?.data)
        ? overlayHistoryMapState.data.mapData
        : null;
    const historyStateActivePoi = overlayHistoryMapStateData?.activePoiKey;
    if (historyStateActivePoi && !mapDataV5EntryTarget) {
        return historyStateActivePoi;
    }

    /**
     * retrun default tile of only one is given, this is also used for entries with poiId parameter
     * overriding any history state
     */
    const mapDataDefault = get(mapDataV5DefaultAtom);
    const defaultTiles = mapDataDefault?.attributes?.tiles;
    if (defaultTiles && Object.keys(defaultTiles).length === 1) {
        const firstDefaultTileKey = Object.keys(defaultTiles)[0];

        if (firstDefaultTileKey) {
            return firstDefaultTileKey;
        }
    }

    return null;
});

export const useMapActivePoiKeyState = () => useAtomValue(mapActivePoiKeyStateAtom);

const mapActiveMultiPinKeyStateAtom = atom<null | string>((get) => {
    const multiPins = get(multiPinsAtom);
    const activePoiKey = get(mapActivePoiKeyStateAtom);
    return Object.entries(multiPins).find(([pinKey]) => pinKey === activePoiKey)?.[1]?.multiPinKey ?? null;
});

export const useMapMultiPinState = () => useAtomValue(mapActiveMultiPinKeyStateAtom);

export const mapActiveTileStateAtom = atom<IMapTile | null>((get) => {
    const activePoiKey = get(mapActivePoiKeyStateAtom);
    const tiles = get(mapTilesStateAtom);
    const fallbackTiles = get(mapDataV5DefaultAtom)?.attributes?.tiles;

    if (!activePoiKey) {
        return null;
    }

    const activeTile = tiles && tiles[activePoiKey];
    if (activeTile) {
        return activeTile;
    }

    const fallbackTile = fallbackTiles && fallbackTiles[activePoiKey];
    if (fallbackTile) {
        return fallbackTile;
    }

    return null;
});

export const useMapDataActiveTileState = () => useAtomValue(mapActiveTileStateAtom);

export const useMapLoadingState = () => {
    const isMapDataDefaultLoading = useAtomValue(isMapDataV5DefaultLoadingAtom);
    const isMapDataByBoundariesLoading = useAtomValue(isMapDataV5ByBoundariesLoadingAtom);
    const isMapDataBySpotLoading = useAtomValue(isMapDataV5BySpotLoadingAtom);
    const isGoogleMapInitialized = useAtomValue(isGoogleMapInitializedAtom);

    const isMapDataFetching = isMapDataDefaultLoading || isMapDataByBoundariesLoading || isMapDataBySpotLoading;

    const [isMapLoading, setIsMapLoading] = React.useState(!isGoogleMapInitialized || isMapDataFetching);

    /**
     * Effect: debounce isMapLoading to overcome gap between loading states while fetching map data
     */
    React.useEffect(() => {
        const isLoading = isMapDataFetching || !isGoogleMapInitialized;
        if (isLoading) {
            setIsMapLoading(isLoading);
            return;
        }

        const timeout = setTimeout(() => {
            setIsMapLoading(false);
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [isGoogleMapInitialized, isMapDataFetching, isMapLoading]);

    return isMapLoading;
};

export const useHydrateMapTravelForm = ({
    hasLoadMoreButton,
    onLoadMoreButtonClick,
}: {
    hasLoadMoreButton: boolean;
    onLoadMoreButtonClick: () => void;
}) => {
    const { isFetching: isDynamicPageFetching } = useDynamicPageQueryFetching();
    const [isMapTravelFormLoading, setMapTravelFormLoading] = useAtom(isMapTravelFormLoadingAtom);
    const isMapLoading = useMapLoadingState();
    const { isTravelFormSubmitLoading } = useTravelFormSubmitLoading();
    const isLoadMoreButtonLoading = useLoadMoreButtonLoading();
    const [isFirstStageLoading, setFirstStageLoading] = React.useState(false);
    const firstStageTimeoutRef = React.useRef<NodeJS.Timeout>();
    const loadMoreTimeoutRef = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        if (isTravelFormSubmitLoading) {
            setMapTravelFormLoading(true);
            setFirstStageLoading(true);
        }
    }, [isTravelFormSubmitLoading, setMapTravelFormLoading]);

    // debounce setFirstStageLoading to overcome gap between loading states
    React.useEffect(() => {
        if (!isMapTravelFormLoading || isTravelFormSubmitLoading || isMapLoading || isDynamicPageFetching) {
            return;
        }

        firstStageTimeoutRef.current = setTimeout(() => {
            setFirstStageLoading(isDynamicPageFetching || isMapLoading || isTravelFormSubmitLoading);
        }, 100);

        return () => {
            clearTimeout(firstStageTimeoutRef.current);
            firstStageTimeoutRef.current = undefined;
        };
    }, [isMapLoading, isMapTravelFormLoading, isDynamicPageFetching, isTravelFormSubmitLoading]);

    // The API can't know whether a boundaries request is sent due to a travel form click or just a map move.
    // So we have to "click" the load more button automatically to directly show live prices after using the travel form
    React.useEffect(() => {
        if (!isMapTravelFormLoading || isFirstStageLoading || !hasLoadMoreButton) {
            return;
        }

        onLoadMoreButtonClick();
    }, [hasLoadMoreButton, isFirstStageLoading, isMapTravelFormLoading, onLoadMoreButtonClick]);

    // debounce setMapTravelFormLoading to overcome gap between renders until isLoadMoreButtonLoading is set to true
    React.useEffect(() => {
        if (!isMapTravelFormLoading || isFirstStageLoading || isLoadMoreButtonLoading) {
            return;
        }

        loadMoreTimeoutRef.current = setTimeout(() => {
            setMapTravelFormLoading(isLoadMoreButtonLoading);
        }, 100);

        return () => {
            clearTimeout(loadMoreTimeoutRef.current);
            loadMoreTimeoutRef.current = undefined;
        };
    }, [isFirstStageLoading, isLoadMoreButtonLoading, isMapTravelFormLoading, setMapTravelFormLoading]);
};
