import type {
    DynamicPage_jsonld_page_read,
    PageComponent,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { isNestedComponent } from '@/core/features/a-component/services/component-service';
import {
    useDynamicPageQueryData,
    useDynamicPageQueryFetching,
} from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';

export default function useDynamicPageComponentByIri<Component extends PageComponent>(
    componentIri: Component['@id'] | null,
    options: { matcher: (iri: string) => string } = { matcher: (iri) => iri },
): Component | null {
    const { data: dynamicPageData } = useDynamicPageQueryData();
    const { isFetching } = useDynamicPageQueryFetching();
    const initialComponent = findComponent<Component>(dynamicPageData?.response, componentIri, options.matcher);
    const [component, setComponent] = React.useState<Component | null>(initialComponent ?? null);

    React.useEffect(() => {
        if (isFetching) {
            return;
        }
        if (!dynamicPageData?.response?.components || !componentIri) {
            setComponent(null);
            return;
        }

        const component = findComponent(dynamicPageData?.response, componentIri, options.matcher);
        if (!component) {
            return;
        }

        setComponent(component);
    }, [dynamicPageData, isFetching, componentIri, options.matcher]);
    return component;
}

const findComponent = <Component extends PageComponent>(
    data: DynamicPage_jsonld_page_read | undefined,
    componentIri: Component['@id'] | null,
    matcher: (iri: string) => string,
): Component | undefined => {
    if (!data?.components || !componentIri) {
        return undefined;
    }

    return data.components
        .flatMap((component) => {
            if (isNestedComponent(component)) {
                return [component, ...component.attributes.components];
            }
            return component;
        })
        .find((component) => {
            return matcher(component['@id']) === matcher(componentIri);
        }) as Component | undefined;
};
