import type { MapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch';
import type { MapSwiperTile } from '@/features/map/map-components/map-swiper/map-swiper-state';
import type { CustomMapZoomControlEventType } from '@/features/map/map-components/map-zoom-control/map-zoom-control';
import type { IMapCluster } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import useAppEvents from '@/core/features/app/use-app-events';
import { useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { useFilterDataV1Mutation } from '@/features/filter/filter-data/use-filter-data-mutation';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import {
    getStoredMapType,
    setStoredMapType,
} from '@/features/map/map-components/map-layer-switch/map-layer-switch-state';
import { useMapSwiperState } from '@/features/map/map-components/map-swiper/map-swiper-state';
import { mapAccommodationTileOverlayOpenAtom } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation/map-accommodation-tile-overlay';
import { logMap } from '@/features/map/map-container/map-logger';
import {
    isGoogleMapInitializedAtom,
    useMapVisitedPois,
    useSetSelectedMapTileHiddenAtom,
    useSetSelectedPoiKeyState,
} from '@/features/map/map-container/map-state';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';
import { useMapPosition } from '@/features/map/map-container/use-map-position';
import {
    useAutoLoadMoreSetting,
    useHydrateAutoLoadMoreSetting,
} from '@/features/map/map-data-v5/auto-load-more-button-state';
import { getBoundariesObject } from '@/features/map/map-data-v5/map-data-v5-service';
import {
    boundariesLoadMoreLivePricesTargetAtom,
    boundariesLoadMoreTargetAtom,
    isMapDataV5ByBoundariesLoadingAtom,
    mapDataV5ByBoundariesAtom,
    mapDataV5DefaultAtom,
    setMapBoundariesAtom,
    shouldInitMapBoundariesForComponentsAtom,
    useHydrateMapDataV5ByBoundaries,
    useHydrateMapDataV5ByPoi,
    useHydrateMapDataV5BySpot,
    useHydrateMapDataV5Default,
    useMultiPins,
    useResetPoiIdInClientUrl,
} from '@/features/map/map-data-v5/use-map-data-v5';

/**
 * Shared hook for map component
 * WARN: only use in map-component.tsx and map-component-desktop.tsx
 */
export default function useMapComponent() {
    // hydrate map states
    useHydrateMapDataV5ByPoi();
    useHydrateMapDataV5BySpot();
    useHydrateMapDataV5ByBoundaries();
    useHydrateMapDataV5Default();
    useHydrateAutoLoadMoreSetting();

    useMapPosition();

    const appEvents = useAppEvents();

    const { saveMapStateToHistoryState } = useMapOverlayHistoryState();

    const [visitedPois, setVisitedPois] = useMapVisitedPois();
    const setActivePoiKey = useSetSelectedPoiKeyState();
    const setSelectedMapTileHiddenAtom = useSetSelectedMapTileHiddenAtom();
    const setMapBoundaries = useSetAtom(setMapBoundariesAtom);

    const setBoundariesLoadMoreTarget = useSetAtom(boundariesLoadMoreTargetAtom);
    const setBoundariesLoadMoreLivePricesTarget = useSetAtom(boundariesLoadMoreLivePricesTargetAtom);

    const [isGoogleMapInitialized, setGoogleMapInitialized] = useAtom(isGoogleMapInitializedAtom);
    const setInitMapBoundariesForComponents = useSetAtom(shouldInitMapBoundariesForComponentsAtom);

    const mapSwiperState = useMapSwiperState();
    const [mapAccommodationTileOverlay, setMapAccommodationTileOverlay] = useAtom(mapAccommodationTileOverlayOpenAtom);

    const resetPoiIdInClientUrl = useResetPoiIdInClientUrl();
    const initialMapType = getStoredMapType();
    const [mapType, setMapType] = React.useState<MapType>(initialMapType);

    const googleMapInstance = useGoogleMapInstance();
    const { resetFilter } = useFilterDataV1Mutation();

    const [mapDataDefault, setMapDataDefault] = useAtom(mapDataV5DefaultAtom);
    const mapDataByBoundaries = useAtomValue(mapDataV5ByBoundariesAtom);

    const multiPins = useMultiPins();

    const { filterGroups, filterInfoText, icons: iconsDefault } = mapDataDefault?.attributes ?? {};
    const {
        accommodationFilters,
        activityToggle,
        filterButton,
        icons: iconByBoundaries,
        loadMoreButton,
        pins,
        travelFormCta,
        verticalToggleIconUrl,
        verticalToggleOptions,
    } = mapDataByBoundaries?.attributes ?? {};

    const icons = iconByBoundaries ?? iconsDefault ?? null;

    // remove map state to prevent old data from being used when map is opened again
    React.useEffect(() => {
        return () => {
            setMapDataDefault(null);
            setSelectedMapTileHiddenAtom(false);
            setActivePoiKey(null);
        };
    }, [setActivePoiKey, setMapDataDefault, setSelectedMapTileHiddenAtom]);

    const onLoadMoreClick = React.useCallback(() => {
        setBoundariesLoadMoreLivePricesTarget(loadMoreButton?.livePricesTargetUrl ?? null);
        setBoundariesLoadMoreTarget(loadMoreButton?.clickTargetUrl ?? null);
    }, [loadMoreButton, setBoundariesLoadMoreLivePricesTarget, setBoundariesLoadMoreTarget]);

    const [isLoadMoreButtonEnabled, setLoadMoreButtonEnabled] = React.useState(false);
    const isMapDataByBoundariesLoading = useAtomValue(isMapDataV5ByBoundariesLoadingAtom);
    const isAutoLoadMoreFeatureFlagEnabled = useAutoLoadMoreSetting();

    React.useEffect(() => {
        if (!isAutoLoadMoreFeatureFlagEnabled || isMapDataByBoundariesLoading) {
            return;
        }
        setLoadMoreButtonEnabled(true);
    }, [isAutoLoadMoreFeatureFlagEnabled, isMapDataByBoundariesLoading, onLoadMoreClick]);

    const onIdle = React.useCallback(() => {
        if (!googleMapInstance || !mapDataDefault) {
            return;
        }

        const boundaries = googleMapInstance.getBounds();
        const zoom = googleMapInstance.getZoom();

        if (!boundaries || !zoom) {
            return;
        }

        logMap('Idle Event');

        setMapBoundaries(getBoundariesObject(boundaries, zoom));
        saveMapStateToHistoryState({ debugNamespace: 'onIdle' });
        // 100ms delay because mapBoundariesForComponents is required and may not yet have been processed otherwise
        setTimeout(() => appEvents.emit('reset_destination'), 100);
    }, [appEvents, googleMapInstance, mapDataDefault, saveMapStateToHistoryState, setMapBoundaries]);

    const onMapClick = React.useCallback(() => {
        setActivePoiKey(null);
        setSelectedMapTileHiddenAtom(true);
        resetPoiIdInClientUrl('poi');
        resetPoiIdInClientUrl('area');
        saveMapStateToHistoryState({ debugNamespace: 'onMapClick', poiKey: null });
    }, [resetPoiIdInClientUrl, saveMapStateToHistoryState, setActivePoiKey, setSelectedMapTileHiddenAtom]);

    const onZoomControlChange = (eventType: CustomMapZoomControlEventType) => {
        if (!googleMapInstance) {
            return;
        }

        resetPoiIdInClientUrl('poi');
        resetPoiIdInClientUrl('area');

        const currentZoom = googleMapInstance.getZoom();

        if (!currentZoom) {
            return;
        }

        if (eventType === 'zoom-in') {
            googleMapInstance.setZoom(currentZoom + 1);
        } else {
            googleMapInstance.setZoom(currentZoom - 1);
        }
    };

    const onClusterClick = (cluster: IMapCluster) => {
        googleMapInstance?.setZoom(cluster.targetZoom ?? (googleMapInstance?.getZoom() ?? 5) + 2);
        googleMapInstance?.panTo({ lat: cluster.point.latitude, lng: cluster.point.longitude });
    };

    const updateActivePoi = (poiKey: string) => {
        setActivePoiKey(poiKey);
        setVisitedPois([...visitedPois, poiKey]);
        saveMapStateToHistoryState({ debugNamespace: 'updateActivePoi', poiKey });
    };

    const onPoiClick = (event: React.MouseEvent, poiKey: string) => {
        event.stopPropagation();
        updateActivePoi(poiKey);
        resetPoiIdInClientUrl('poi');
    };

    const onMapSwiperTileChange = (tile: MapSwiperTile) => {
        if (!pins) {
            return;
        }

        const iMapMarker = pins[tile.markerKey] ?? multiPins[tile.markerKey];

        if (iMapMarker?.markerType !== 'poi') {
            return;
        }

        updateActivePoi(tile.markerKey);
    };

    const updateMapType = (mapType: MapType) => {
        googleMapInstance?.setMapTypeId(mapType);

        setStoredMapType(mapType);
        setMapType(mapType);
    };

    const handleResetFilter = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        resetFilter();
    };

    const idleTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const clearIdleTimeoutRef = React.useCallback(() => {
        if (idleTimeoutRef.current === null) {
            return;
        }

        clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = null;
    }, []);

    React.useEffect(() => {
        if (!googleMapInstance) {
            return;
        }

        const listeners: google.maps.MapsEventListener[] = [];

        listeners.push(
            googleMapInstance.addListener('click', () => {
                clearIdleTimeoutRef();
                onMapClick();
            }),
        );

        listeners.push(
            googleMapInstance.addListener('idle', () => {
                clearIdleTimeoutRef();

                idleTimeoutRef.current = setTimeout(() => {
                    setGoogleMapInitialized(true);
                    onIdle();
                }, 500);
            }),
        );

        listeners.push(
            googleMapInstance.addListener('dragstart', () => {
                clearIdleTimeoutRef();
            }),
        );

        listeners.push(
            googleMapInstance.addListener('dragend', () => {
                clearIdleTimeoutRef();
                resetPoiIdInClientUrl('poi');
                resetPoiIdInClientUrl('area');
                setInitMapBoundariesForComponents(true);
            }),
        );

        listeners.push(
            googleMapInstance.addListener('zoom_changed', () => {
                clearIdleTimeoutRef();
                resetPoiIdInClientUrl('poi');
                resetPoiIdInClientUrl('area');
                if (isGoogleMapInitialized) {
                    setInitMapBoundariesForComponents(true);
                }
            }),
        );

        return () => {
            listeners.forEach((listener) => {
                google.maps.event.removeListener(listener);
            });
        };
    }, [
        appEvents,
        clearIdleTimeoutRef,
        googleMapInstance,
        isGoogleMapInitialized,
        onIdle,
        onMapClick,
        resetPoiIdInClientUrl,
        setGoogleMapInitialized,
        setInitMapBoundariesForComponents,
    ]);

    return {
        accommodationFilters,
        activityToggle,
        filterButton,
        filterGroups,
        filterInfoText,
        handleResetFilter,
        icons,
        isLoadMoreButtonEnabled,
        loadMoreButton,
        mapAccommodationTileOverlay,
        mapSwiperState,
        mapType,
        onClusterClick,
        onLoadMoreClick,
        onMapSwiperTileChange,
        onPoiClick,
        onZoomControlChange,
        pins,
        setMapAccommodationTileOverlay,
        travelFormCta,
        updateMapType,
        verticalToggleIconUrl,
        verticalToggleOptions,
    };
}
