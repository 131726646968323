import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';
import type { TravelFormV1Attributes } from '@/features/travel-form/travel-form-v1/travel-form-v1-type';

import React from 'react';

import { useDynamicPageQueryFetching } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import useAppEvents from '@/core/features/app/use-app-events';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { useScrollToTarget } from '@/core/features/link/use-scroll-to-target';
import { useRouterLink } from '@/core/features/router/router-link';
import useTravelFormDataV1 from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import TravelFormV1 from '@/features/travel-form/travel-form-v1/travel-form-v1';
import TravelFormV1Desktop from '@/features/travel-form/travel-form-v1/travel-form-v1-desktop/travel-form-v1-desktop';
import { trackTravelFormCTAClick } from '@/features/travel-form/use-travel-form-tracking';

type TravelFormV1ContainerProps = PageComponentProps<TravelFormV1Attributes>;

export default function TravelFormV1Container({ attributes, isWidget, meta }: TravelFormV1ContainerProps) {
    const { isNavigating } = useRouterLink();
    const { isFetching: isDynamicPageFetching } = useDynamicPageQueryFetching();
    const { banner, travelForm: partialTravelForm } = attributes;
    const [isOverlayOpen, setOverlayOpen] = React.useState(false);
    const [overlayType, setOverlayType] = React.useState<TravelFormOverlayName | null>(null);
    const [isPartialTravelForm, setPartialTravelForm] = React.useState(true);

    const { isDesktopView } = useDeviceoutput();
    const appEvents = useAppEvents();

    const {
        activeFilters,
        applyFilter,
        initialCtaLinkUrl,
        isLoading,
        requestTargets,
        scrollTo,
        setTargetComponentIri,
        submitForm,
        travelForm: travelFormData,
    } = useTravelFormDataV1({
        enabled: !isPartialTravelForm,
    });
    const { scrollTo: scrollToComponent } = useScrollToTarget(scrollTo?.scrollToTarget ?? null);

    const [hasSubmitted, setHasSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (hasSubmitted && !isNavigating) {
            setTimeout(() => {
                scrollToComponent();
                setHasSubmitted(false);
            }, 400);
        }
    }, [hasSubmitted, isNavigating, scrollToComponent, isDynamicPageFetching]);

    React.useEffect(() => {
        appEvents.on('filter_change', () => {
            setPartialTravelForm(true);
            setTargetComponentIri(null);
        });
    }, [appEvents, setTargetComponentIri]);

    const fields = travelFormData?.filters ?? partialTravelForm.fields;

    const onOverlayCtaClick = (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => {
        applyFilter(filterName, filterValue, requestTarget);
        setOverlayOpen(false);
    };

    const onInputClick = (overlayName: TravelFormOverlayName) => {
        setPartialTravelForm(false);
        setOverlayType(overlayName);
        setOverlayOpen(true);
    };

    const onSubmit = () => {
        const linkUrl = travelFormData?.cta.linkUrl ?? initialCtaLinkUrl ?? partialTravelForm.cta.linkUrl;
        const activityFilters = travelFormData?.filters.activity;

        const selectedActivitiesNames = activityFilters?.selectedItems
            .sort((activity1, activity2) => activity1.id - activity2.id)
            .map((selectedActivity) => selectedActivity.title);

        setHasSubmitted(true);
        trackTravelFormCTAClick(selectedActivitiesNames ?? []);
        submitForm(linkUrl);
    };

    const formConfiguration = {
        activeFilters,
        cta: {
            dependsOn: travelFormData?.cta.dependsOn ?? partialTravelForm.cta.dependsOn,
            text: travelFormData?.cta.text ?? partialTravelForm.cta.text,
        },
        fields,
        isLoading,
        noPadding: true,
        onInputClick,
        onSubmit,
    };

    if (isDesktopView) {
        return (
            <TravelFormV1Desktop
                activityRequestTarget={requestTargets.activityRequestTarget}
                airportRequestTarget={requestTargets.airportsRequestTarget}
                banner={banner}
                dateRequestTarget={requestTargets.dateRequestTarget}
                destinationChangeRequestTarget={requestTargets.destinationChangeRequestTarget}
                destinationRequestTarget={requestTargets.destinationRequestTarget}
                formConfiguration={{
                    ...formConfiguration,
                    inputSize: partialTravelForm.orientation === 'vertical' ? 'normal' : 'large',
                    orientation: partialTravelForm.orientation,
                }}
                isOverlayOpen={isOverlayOpen}
                isSticky={meta?.position === 'sticky'}
                isWidget={isWidget ?? false}
                onInputClick={onInputClick}
                onOverlayClose={() => setOverlayOpen(false)}
                onOverlayCtaClick={onOverlayCtaClick}
                overlayType={overlayType}
                travelForm={travelFormData}
            />
        );
    }

    return (
        <TravelFormV1
            activityRequestTarget={requestTargets.activityRequestTarget}
            airportsRequestTarget={requestTargets.airportsRequestTarget}
            banner={banner}
            dateRequestTarget={requestTargets.dateRequestTarget}
            destinationChangeRequestTarget={requestTargets.destinationChangeRequestTarget}
            destinationRequestTarget={requestTargets.destinationRequestTarget}
            formConfiguration={formConfiguration}
            isOverlayOpen={isOverlayOpen}
            onOverlayClose={() => setOverlayOpen(false)}
            onOverlayCtaClick={onOverlayCtaClick}
            overlayType={overlayType}
            travelForm={travelFormData}
        />
    );
}
