import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import useAppEvents from '@/core/features/app/use-app-events';
import { useRouterLink } from '@/core/features/router/router-link';
import { useAtomValue } from '@/core/features/store/atom-store';
import { getURL } from '@/core/utils/url';
import { mapBoundariesForComponentsAtom } from '@/features/map/map-data-v5/use-map-data-v5';

export function useResetDestinationListener() {
    const appEvent = useAppEvents();
    const { navigate } = useRouterLink();
    const [clientUrl] = useClientUrl();
    const mapBoundariesForComponents = useAtomValue(mapBoundariesForComponentsAtom);

    const resetDestination = React.useCallback(() => {
        const url = getURL(clientUrl);
        if (!mapBoundariesForComponents || !url.searchParams.has('destination')) {
            return;
        }

        url.searchParams.delete('destination');
        navigate(url.toString(), { historyType: 'historyReplace' });
    }, [clientUrl, mapBoundariesForComponents, navigate]);

    React.useEffect(() => {
        appEvent.on('reset_destination', resetDestination);
        return () => {
            appEvent.off('reset_destination', resetDestination);
        };
    }, [appEvent, resetDestination]);
}
