import type {
    DynamicPage_jsonld_page_read,
    PageComponent,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { isNestedComponent } from '@/core/features/a-component/services/component-service';
import {
    useDynamicPageQueryData,
    useDynamicPageQueryFetching,
} from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';

export default function useDynamicPageComponentByType<T extends PageComponent>(type: T['@type']): T | null {
    const { data, error } = useDynamicPageQueryData();
    const { isFetching } = useDynamicPageQueryFetching();

    const initialComponent = findComponent<T>(data?.response, type);
    const [component, setComponent] = React.useState<T | null>(initialComponent ?? null);

    React.useEffect(() => {
        if (!data || error) {
            setComponent(null);
            return;
        }

        const component = findComponent(data.response, type);
        if (!component) {
            return;
        }

        setComponent(component);
    }, [data, isFetching, error, type]);

    return component;
}

const findComponent = <T extends PageComponent>(
    data: DynamicPage_jsonld_page_read | undefined,
    type: T['@type'],
): T | undefined => {
    if (!data?.components) {
        return undefined;
    }

    return data.components
        .flatMap((component) => {
            if (isNestedComponent(component)) {
                return component.attributes.components;
            }
            return component;
        })
        .find((component) => component['@type'] === type) as T | undefined;
};
