import type { ToggleFilterActionType } from '@/features/filter/filter-data/service/filter-toggle-service';
import type { FilterGroupV1 } from '@/features/filter/filter-data-v1-type';

import React from 'react';

import { updateDynamicPage } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useClientUrl } from '@/core/features/app/app-atoms';
import useAppEvents from '@/core/features/app/use-app-events';
import { useQueryClient } from '@/core/features/react-query/react-query-service';
import { useRouterLink } from '@/core/features/router/router-link';
import clone from '@/core/utils/clone';
import { type SearchParams, getUrlPathWithSearch, updateSearchParams } from '@/core/utils/url';
import { useAccommodationFilterState } from '@/features/accommodation-filter/accommodation-filter-state';
import {
    useFilterDataV1Attributes,
    useSortModalFilterGroup,
} from '@/features/filter/filter-data/filter-data-attributes-state';
import { useFilterLoading } from '@/features/filter/filter-data/filter-state';
import { getUpdatedSearchParams } from '@/features/filter/filter-data/service/filter-search-params-service';
import {
    resetFilterAttributes,
    toggleFilterInFilterAttributes,
} from '@/features/filter/filter-data/service/filter-toggle-service';
import {
    trackFilterChange,
    trackMultipleFilterChanges,
    trackRemovedFilters,
} from '@/features/filter/filter-tracking/filter-tracking-service';

export const useAccommodationFilterDataMution = () => {
    const { accommodationFilters } = useAccommodationFilterState();

    return useFilterDataMutation(accommodationFilters?.groups ?? null);
};

export const useFilterDataV1Mutation = () => {
    const [filterDataV1Attributes] = useFilterDataV1Attributes();

    return useFilterDataMutation(filterDataV1Attributes.groups);
};

export const useSortFilterDataMutation = () => {
    const [filterDataV1Attributes] = useFilterDataV1Attributes();

    const sortModalFilterGroup = useSortModalFilterGroup();

    const { toggleFilter } = useFilterDataMutation(filterDataV1Attributes.groups);

    const toggleSortFilter = React.useCallback(
        (optionName: string) => {
            const option = sortModalFilterGroup?.options.find((option) => option.name === optionName);
            if (!option) {
                return;
            }
            const groupName = sortModalFilterGroup ? sortModalFilterGroup.name : '';
            toggleFilter({
                groupName,
                isDeactivated: option.count === 0,
                isDefault: option.isDefault,
                isSelected: false,
                optionNames: [optionName],
                type: 'select',
            });
        },
        [sortModalFilterGroup, toggleFilter],
    );

    return toggleSortFilter;
};

const useFilterDataMutation = (filterGroups: FilterGroupV1[] | null) => {
    const [isFilterLoading, setFilterLoading] = useFilterLoading();
    const [clientUrl, setClientUrl] = useClientUrl();
    const queryClient = useQueryClient();
    const appEvents = useAppEvents();
    const { navigate } = useRouterLink();

    const updateFilterPageUrl = React.useCallback(
        async (newSearchParams: SearchParams) => {
            const newUrl = getUrlPathWithSearch(updateSearchParams(clientUrl, newSearchParams));

            await updateDynamicPage({
                clientUrl,
                navigate,
                newUrl,
                queryClient,
                setClientUrl,
            });

            setTimeout(() => {
                // prevents unwanted modal animation flickering
                setFilterLoading(false);
            }, 100);
        },
        [clientUrl, navigate, queryClient, setClientUrl, setFilterLoading],
    );

    const resetFilter = React.useCallback(() => {
        if (isFilterLoading || !filterGroups) {
            return;
        }

        setFilterLoading(true);

        const updatedFilterGroups = resetFilterAttributes(clone(filterGroups));

        const updatedSearchParams = getUpdatedSearchParams(clientUrl, updatedFilterGroups, undefined, 'reset');

        appEvents.emit('filter_change');

        trackRemovedFilters(filterGroups, clientUrl);

        updateFilterPageUrl(updatedSearchParams);
    }, [appEvents, clientUrl, filterGroups, isFilterLoading, setFilterLoading, updateFilterPageUrl]);

    const toggleFilter: ToggleFilterActionType = React.useCallback(
        (payload) => {
            if (!filterGroups) {
                return;
            }
            const { groupName, isDeactivated, isQuickFilterDeselect, isSelected, optionNames, type } = payload;

            if (isFilterLoading || (!isSelected && isDeactivated)) {
                return;
            }

            setFilterLoading(true);

            const updatedFilterGroups = toggleFilterInFilterAttributes(clone(filterGroups), payload);

            const resetFilterGroup = (optionNames.length > 1 && type === 'deselect') || isQuickFilterDeselect;

            const updatedSearchParams = getUpdatedSearchParams(
                clientUrl,
                updatedFilterGroups,
                groupName,
                resetFilterGroup ? 'reset' : type,
            );

            appEvents.emit('filter_change');

            payload.optionNames.forEach((optionName) => {
                trackFilterChange({
                    filterName: payload.groupName,
                    filterValue: optionName,
                    isSelected: payload.isSelected,
                });
            });
            updateFilterPageUrl(updatedSearchParams);
        },
        [appEvents, clientUrl, filterGroups, isFilterLoading, setFilterLoading, updateFilterPageUrl],
    );

    const toggleMultipleFiltersAtOnce = (filterGroup: FilterGroupV1, groupName: string) => {
        if (!filterGroups) {
            return;
        }

        setFilterLoading(true);
        const updatedSearchParams = getUpdatedSearchParams(
            clientUrl,
            filterGroups.map((group) => {
                return group.name === filterGroup.name ? filterGroup : group;
            }),
            groupName,
            'select',
        );
        trackMultipleFilterChanges(groupName, clientUrl, updatedSearchParams);

        updateFilterPageUrl(updatedSearchParams);
    };

    return {
        resetFilter,
        toggleFilter,
        toggleMultipleFiltersAtOnce,
    };
};
