import type { TrackingMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';
import type { Deviceoutput } from '@/core/features/cookies/cookies-service';
import type { SafeOmit } from '@/core/types/safe-omit';

import { IS_SERVER, NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME, NEXT_PUBLIC_SSO_PRODUCT_ID } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';
import FeatureFlagStore from '@/core/features/store/feature-flags-store';

const featureFlagStore = FeatureFlagStore();

export type VirtualPageviewParameters = {
    c24consent: null | string;
    deviceoutput: Deviceoutput;
    login: SsrProps['user']['loginStatus'];
    sid: string;
};

type DataLayerEventType =
    | { [key: string]: unknown; element: string; event: 'click' }
    | { [key: string]: unknown; element: string; event: 'link' }
    | { [key: string]: unknown; event: 'virtualPageview' };

export const trackVirtualPageview = (
    trackingMeta: SafeOmit<TrackingMeta, 'generalTrackingPixels'>,
    virtualPageviewParameters: VirtualPageviewParameters,
    debugNamespace: string,
) => {
    const event: DataLayerEventType = {
        event: 'virtualPageview',
        product42: NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME,
        productName: NEXT_PUBLIC_SSO_PRODUCT_ID,
        ...trackingMeta,
        ...virtualPageviewParameters,
    };

    dataLayerPush(event, debugNamespace);

    return event;
};

export const dataLayerPush = (event: DataLayerEventType, debugNamespace?: string, retry = false): void => {
    if (IS_SERVER) {
        logger.error('Using dataLayer server-side is not allowed.', { serverOnly: true });
        return;
    }
    if (!window.dataLayer) {
        // we load google dataLayer async for performance reason
        // but we might fire tracking events earlier that tag manager has loaded
        if (retry) {
            return;
        }

        setTimeout(() => {
            return dataLayerPush(event, debugNamespace, true);
        }, 2000);

        return;
    }

    if (!IS_SERVER) {
        // make console log non-blocking so it does not interfere with runtime
        setTimeout(() => {
            // eslint-disable-next-line no-console
            featureFlagStore.logTable('trackingLogs', `TrackingLog: dataLayerPush: ${debugNamespace ?? ''}`, event);
        }, 0);
    }

    window.dataLayer.push(event);
};
