import React from 'react';

import { scrollToTop } from '@/core/features/scroll/scroll-service';
import useIsScrolling from '@/core/features/scroll/use-is-scrolling';
import { useFilterDataV1Mutation } from '@/features/filter/filter-data/use-filter-data-mutation';

type UpdateScrollTimeoutId = (newId: NodeJS.Timeout | null) => void;

export default function useFilterHandlerDesktop(onFilterReset?: () => void) {
    const isScrolling = useIsScrolling({ scrollType: 'wheel' });
    const { resetFilter } = useFilterDataV1Mutation();

    const [hasFilterChanged, setHasFilterChanged] = React.useState(false);
    const [hasScrolled, setHasScrolled] = React.useState(false);
    const [scrollTimeoutId, setScrollTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    const updateScrollTimeoutId: UpdateScrollTimeoutId = React.useCallback(
        (newId) => {
            if (scrollTimeoutId) {
                clearTimeout(scrollTimeoutId);
            }
            setHasScrolled(false);
            setScrollTimeoutId(newId);
        },
        [scrollTimeoutId],
    );

    const handleFilterChange = React.useCallback(() => {
        setHasScrolled(false);
        const newTimeout = setTimeout(() => {
            if (!hasScrolled) {
                scrollToTop();
            }
            updateScrollTimeoutId(null);
            setHasFilterChanged(false);
        }, 3000);

        updateScrollTimeoutId(newTimeout);
        setHasFilterChanged(true);
    }, [hasScrolled, updateScrollTimeoutId]);

    const handleFilterReset = React.useCallback(() => {
        resetFilter();
        if (onFilterReset) {
            onFilterReset();
            setHasScrolled(false);
            return;
        }
        scrollToTop();
        setHasScrolled(false);
    }, [onFilterReset, resetFilter]);

    React.useEffect(() => {
        if (isScrolling && hasFilterChanged) {
            setHasScrolled(true);
            updateScrollTimeoutId(null);
        }
    }, [isScrolling, hasFilterChanged, updateScrollTimeoutId]);

    return { handleFilterChange, handleFilterReset };
}
