import type { NavigationBarV1Attributes } from '@/features/navigation-bar-v1/navigation-bar-v1-type';

import { Link, RawImage } from '@/core/features';
import color from '@/core/features/styles/color';

import styles from './navigation-bar-v1-desktop.module.scss';

export type NavigationBarProps = NavigationBarV1Attributes;

export default function NavigationBarV1Desktop({ items }: NavigationBarProps) {
    return (
        <nav className={styles.navigationBar}>
            {items.map((item) => {
                return (
                    <Link
                        className={'min-width-fit-content'}
                        href={item.linkUrl}
                        key={item.title}
                        linkType={'vertical'}
                        relType={'follow'}
                    >
                        <div
                            className={`text-nowrap align-center border-radius-20 line-height-32 font-size-14 border-transparent ${styles.navigationItem}`}
                            style={
                                item.isHighlighted
                                    ? {
                                          backgroundColor: color('stTropaz'),
                                          borderColor: color('white'),
                                      }
                                    : {}
                            }
                        >
                            <div className={styles.navigationItemIcon}>
                                <RawImage
                                    alt={`${item.title} icon`}
                                    fetchPriority={'high'}
                                    height={32}
                                    preload={true}
                                    style={{
                                        padding: item.padding ?? undefined,
                                    }}
                                    url={item.iconUrl}
                                    // width={32} // TODO: Flug + Hotel padding 3px 6px 3px 0px + other optimizations
                                />
                            </div>
                            {item.title}
                        </div>
                    </Link>
                );
            })}
        </nav>
    );
}
