import type { BadgeProps } from '@/core/components/badges/badge';
import type { BadgeType, BadgeWithTooltipType } from '@/core/components/badges/badge-type';
import type { OnClickEvent } from '@/core/types/react-overrides';
import type { SafeOmit } from '@/core/types/safe-omit';

import React from 'react';
import { createPortal } from 'react-dom';

import { Badge, ModalWithText, Tooltip, TooltipTextBox } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { classnames } from '@/core/utils/bem-classname';

export type BadgesProps = SafeOmit<BadgeProps, 'badge'> & {
    badges: BadgeType[];
    marginBottom?: number;
    overlayContainerRef?: React.MutableRefObject<Element | null>;
    qaId?: string;
    type?: 'horizontal' | 'vertical-end' | 'vertical-start';
    wrap?: 'nowrap' | 'wrap';
};

function isBadgeWithTooltip(badge: BadgeType | BadgeWithTooltipType): badge is BadgeWithTooltipType {
    return !!(badge as BadgeWithTooltipType).htmlTooltipText;
}

export default function Badges({
    badges,
    cursor,
    fontSize,
    marginBottom,
    onClick,
    overlayContainerRef,
    padding,
    qaId,
    type = 'vertical-start',
    wrap = 'nowrap',
}: BadgesProps) {
    const badgesRef = React.useRef<HTMLDivElement | null>(null);
    const { isDesktopView } = useDeviceoutput();
    const [isBadgeOverlayOpen, setIsBadgeOverlayOpen] = React.useState(false);
    const [selectedBadge, setSelectedBadge] = React.useState<null | string>(null);

    const modalContent = badges.find((badge) => badge.htmlText === selectedBadge);
    const cursorStyle = cursor || (onClick ? 'pointer' : undefined);

    const portalContainer = overlayContainerRef?.current ?? badgesRef.current;

    if (badges.length === 0) {
        return null;
    }

    const handleBadgesOverlayClick = (event: React.MouseEvent<HTMLDivElement>, htmlText: string) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedBadge(htmlText);
        setIsBadgeOverlayOpen(true);
    };

    const handleBadgesOverlayClose = (event?: KeyboardEvent | OnClickEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        setSelectedBadge(null);
        setIsBadgeOverlayOpen(false);
    };

    const handleOverlayClick = (event: OnClickEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div
            className={classnames(`flex column-gap-10 row-gap-5 max-width-100 ${wrap}`, {
                ['flex-column align-end']: type === 'vertical-end',
                ['flex-column align-start']: type === 'vertical-start',
                ['flex-row justify-start']: type === 'horizontal',
                ['gap-10']: isDesktopView,
            })}
            data-qa-id={'qa-badges-box'}
            ref={badgesRef}
            style={{ marginBottom: marginBottom ? `${marginBottom}px` : undefined }}
        >
            {badges.map((badge, index) => (
                <React.Fragment key={`${badge.htmlText}-${index}`}>
                    {isBadgeWithTooltip(badge) && isDesktopView ? (
                        <Tooltip
                            ariaLabel={badge.htmlText}
                            className={'border-radius'}
                            content={
                                <TooltipTextBox
                                    hasHyphen={true}
                                    htmlText={badge.htmlTooltipText ?? ''}
                                    htmlTitle={null}
                                />
                            }
                            key={`${badge.htmlText}-${index}`}
                            tippyConfig={{ appendTo: () => document.body, offset: [0, 10], placement: 'bottom' }}
                        >
                            <Badge
                                badge={badge}
                                cursor={cursorStyle}
                                fontSize={fontSize}
                                onClick={onClick}
                                padding={padding}
                                qaId={qaId}
                            />
                        </Tooltip>
                    ) : (
                        <Badge
                            badge={badge}
                            cursor={cursorStyle}
                            fontSize={fontSize}
                            key={`${badge.htmlText}-${index}`}
                            onClick={(event) => handleBadgesOverlayClick(event, badge.htmlText)}
                            padding={padding}
                        />
                    )}
                </React.Fragment>
            ))}
            {modalContent &&
                isBadgeWithTooltip(modalContent) &&
                modalContent.htmlTooltipText &&
                !isDesktopView &&
                isBadgeOverlayOpen &&
                portalContainer &&
                createPortal(
                    <ModalWithText
                        htmlText={modalContent.htmlTooltipText}
                        htmlTitle={modalContent.htmlText}
                        onClick={handleOverlayClick}
                        onClose={handleBadgesOverlayClose}
                    />,
                    portalContainer,
                )}
        </div>
    );
}
