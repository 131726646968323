import type { ImageType } from '@/core/features/image-v2/image-type';

import React from 'react';

import ActivityTileDesktopV1 from '@/features/activity-tiles/activity-tiles-v1-desktop/activity-tile-desktop';

type TileColumnsConfig = {
    className: string;
    end?: number;
    start: number;
    twoInTotal?: boolean;
};

export type ActivityTilesV1DesktopProps = {
    tiles: {
        image: ImageType;
        linkUrl: string;
        scrollToTarget: null | string;
        subtitle?: null | string;
        title: string;
    }[];
};

export default function ActivityTilesV1Desktop({ tiles }: ActivityTilesV1DesktopProps) {
    const config = tileColumnsConfig[tiles.length];
    if (!config) {
        return null;
    }
    return (
        <>
            <style jsx>
                {`
                    .imageContainer {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        width: calc(100% + 20px);
                    }

                    .threeImages {
                        width: calc(33.33% - 20px);
                        max-height: 200px;
                    }

                    .fiveImages {
                        width: calc(20% - 20px);
                        max-height: 200px;
                    }

                    .fourImages {
                        width: calc(25% - 20px);
                        max-height: 200px;
                    }

                    .fullWidth {
                        width: calc(100% - 20px);
                    }
                `}
            </style>
            <div className={'imageContainer'}>
                {config.map(({ className, end, start }, index) => (
                    <React.Fragment key={`${className}-${index}`}>
                        {tiles.slice(start, end).map(({ image, linkUrl, scrollToTarget, subtitle, title }, index) => (
                            <div
                                className={className}
                                key={`${linkUrl}-${index}`}
                            >
                                <ActivityTileDesktopV1
                                    height={tiles.length === 1 ? 370 : 200}
                                    image={image}
                                    layoutWidth={33}
                                    linkUrl={linkUrl}
                                    scrollToTarget={scrollToTarget}
                                    subtitle={subtitle}
                                    title={title}
                                />
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}

const tileColumnsConfig: Record<number, TileColumnsConfig[]> = {
    1: [{ className: 'fullWidth', start: 0, twoInTotal: false }],
    2: [{ className: 'fourImages', start: 0 }],
    3: [{ className: 'fourImages', start: 0 }],
    4: [{ className: 'fourImages', start: 0 }],
    5: [{ className: 'fiveImages', start: 0 }],
    6: [{ className: 'threeImages', start: 0 }],
    7: [
        { className: 'threeImages', end: 3, start: 0 },
        { className: 'fourImages', start: 3 },
    ],
    8: [{ className: 'fourImages', start: 0 }],
    9: [
        { className: 'fourImages', end: 4, start: 0 },
        { className: 'fiveImages', start: 4 },
    ],
    10: [{ className: 'fiveImages', start: 0 }],
    11: [
        { className: 'fourImages', end: 4, start: 0 },
        { className: 'threeImages', end: 7, start: 4 },
        { className: 'fourImages', start: 7 },
    ],
    12: [{ className: 'fourImages', start: 0 }],
    13: [
        { className: 'fourImages', end: 4, start: 0 },
        { className: 'fiveImages', end: 9, start: 4 },
        { className: 'fourImages', start: 9 },
    ],
    14: [
        { className: 'fiveImages', end: 5, start: 0 },
        { className: 'fourImages', end: 9, start: 5 },
        { className: 'fiveImages', start: 9 },
    ],
    15: [{ className: 'fiveImages', start: 0 }],
};
