import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentConfig } from '@/features/a-dynamic-page/components/page-component-registry-type';
import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';
import PageComponentLazyWrapper from '@/features/a-dynamic-page/components/page-component-lazy-wrapper';

type PageComponentLazyApiWrapperProps<Attributes, Meta> = React.PropsWithChildren<{
    component: PageComponent;
    componentConfig: PageComponentConfig;
    componentProps: PageComponentProps<Attributes, Meta>;
}>;

export function PageComponentLazyApiWrapper<Attributes, Meta>(
    props: PageComponentLazyApiWrapperProps<Attributes, Meta>,
) {
    const { children, component, componentConfig, componentProps } = props;

    const [inView, setInView] = React.useState(false);

    const componentTargetIri = inView ? componentProps.componentIri : null;
    const { data, isError, isSuccess } = useComponentDataByIri(componentTargetIri, {
        is404Accpeted: true,
        queryName: componentProps.componentType,
    });

    if (isError) {
        return null;
    }

    const Component = componentConfig.render;
    if (Component === null) {
        return null;
    }

    const { attributes } = data ?? component;
    const newComponentProps = {
        ...attributes,
        ...componentProps,
        attributes,
    };

    return (
        <>
            <PageComponentLazyWrapper
                componentId={componentProps.componentId}
                componentType={componentProps.componentType}
                hasLazyApiWrapperFetched={isSuccess}
                lazyConfig={componentConfig.lazyConfig}
                pageComponentIndex={componentProps.pageComponentIndex}
                setLazyWrapperInView={setInView}
            >
                <Component {...newComponentProps}>{children}</Component>
            </PageComponentLazyWrapper>
        </>
    );
}
