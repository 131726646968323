export default function GlobalCss() {
    return (
        <style
            global
            jsx
        >
            {`
                html,
                body {
                    padding: 0;
                    margin: 0;

                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    -moz-text-size-adjust: none;
                    -webkit-text-size-adjust: none;
                    text-size-adjust: none;
                    scrollbar-gutter: stable;
                    font-family:
                        Arial,
                        -apple-system,
                        Helvetica,
                        sans-serif;
                    color: #333333;
                    font-size: 16px;
                    padding-bottom: env(safe-area-inset-bottom, 0);
                    background-color: #f4f4f4;
                }

                .body--desktop {
                    font-family:
                        Verdana,
                        -apple-system,
                        Helvetica,
                        sans-serif;
                    color: #333333;
                    font-size: 14px;
                    background-color: #ffffff;
                }

                .body--desktop main {
                    background-color: #ffffff;
                }

                .body--Windows {
                    &::-webkit-scrollbar {
                        width: 7px;
                        height: 10%;
                        margin: 10px 0;
                    }

                    &::-webkit-scrollbar-track {
                        background: #ebebeb;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 100%;
                        background-color: #b4b4b4;
                    }

                    *::-webkit-scrollbar {
                        width: 7px;
                        height: 10%;
                        margin: 10px 0;
                    }
                    *::-webkit-scrollbar-track {
                        background: #ebebeb;
                        border-radius: 6px;
                    }
                    *::-webkit-scrollbar-thumb {
                        width: 100%;
                        background-color: #b4b4b4;
                        border-radius: 3px;
                    }
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }

                * {
                    margin: 0;
                    padding: 0;
                    font-size: 1em;
                    font-family: inherit;
                    -webkit-tap-highlight-color: transparent;
                }

                strong,
                b {
                    font-weight: bold;
                }

                u {
                    text-decoration: underline;
                }

                i {
                    font-style: italic;
                }

                input,
                select:focus,
                textarea {
                    font-size: 100%;
                }

                /* Prevents image alt texts to render on firefox while the image is loading */
                img:-moz-loading {
                    visibility: hidden;
                }

                /***
                    The new CSS reset - version 1.6.1 (last updated 25.5.2022)
                    GitHub page: https://github.com/elad2412/the-new-css-reset
                ***/

                /*
                    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
                    - The "symbol *" part is to solve Firefox SVG sprite bug
                */
                *:where(:not(html, iframe, canvas, img, svg, video, td, tr, table, tbody):not(svg *, symbol *)) {
                    all: unset;
                    display: revert;
                }

                /* Preferred box-sizing value */
                *,
                *::before,
                *::after {
                    box-sizing: border-box;
                }

                /* Reapply the pointer cursor for anchor tags */
                a,
                button {
                    cursor: revert;
                }

                /* Remove list styles (bullets/numbers) */
                ol,
                ul,
                menu {
                    list-style: none;
                }

                /* For images to not be able to exceed their container */
                img {
                    max-width: 100%;
                }

                /* removes spacing between cells in tables */
                table {
                    border-collapse: collapse;
                }

                /* revert the 'white-space' property for textarea elements on Safari */
                textarea {
                    white-space: revert;
                }

                /* minimum style to allow to style meter element */
                meter {
                    -webkit-appearance: revert;
                    appearance: revert;
                }

                /* reset default text opacity of input placeholder */
                ::placeholder {
                    color: unset;
                }

                /* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
                :where([hidden]) {
                    display: none;
                }

                /* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly. */
                :where([contenteditable]:not([contenteditable='false'])) {
                    -moz-user-modify: read-write;
                    -webkit-user-modify: read-write;
                    overflow-wrap: break-word;
                    -webkit-line-break: after-white-space;
                    line-break: after-white-space;
                }

                /* apply back the draggable feature - exist only in Chromium and Safari */
                :where([draggable='true']) {
                    -webkit-user-drag: element;
                }
            `}
        </style>
    );
}
