import type { SsrProps } from '@/core/features/app/ssr-props';
import type { AppProps as NextSsrProps } from 'next/app';

import React from 'react';

import dynamic from 'next/dynamic';

import '@/assets/global-styles/colors.css';
import GlobalCss from '@/assets/global-styles/global-css';
import '@/assets/global-styles/util-classes.css';
import '@/assets/global-styles/z-index.css';
import { ErrorBoundaryWrapper } from '@/core/features';
import { createQueryClient } from '@/core/features/a-dynamic-page/dynamic-page-query-client';
import AppContextWrapper from '@/core/features/app/app-context-wrapper';
import { mapSsrProps } from '@/core/features/app/ssr-props';
import { isAppSplashAvailable } from '@/core/features/app-promotion/app-promotion-service';
import LazyHydrateOn from '@/core/features/lazy/lazy-hydrate-on';
import '@/core/features/logger/console';
import logger from '@/core/features/logger/logger';
import HeadDynamic from '@/core/features/page/head-dynamic';
import { Hydrate, QueryClientProvider } from '@/core/features/react-query/react-query-service';
import { useWireframeConsentBannerVisibleListener } from '@/core/features/wireframe/use-wireframe-consent-banner-visible';
import WireframeSwitch from '@/core/features/wireframe/wireframe-switch';

const HiddenMenu = dynamic(
    () => import(/* webpackChunkName: "HiddenMenu" */ '@/core/features/hidden-menu/hidden-menu'),
);
const AppSplash = dynamic(() => import(/* webpackChunkName: "AppSplash" */ '@/core/features/app-promotion/app-splash'));

const unregisterServiceWorker = async () => {
    if (!navigator || !navigator.serviceWorker) {
        return;
    }

    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach((registration) => {
        registration.unregister();
    });
};

export default function App({ Component, pageProps, router }: NextSsrProps<SsrProps>) {
    const [queryClient] = React.useState(createQueryClient(router.asPath));

    const ssrProps: SsrProps = mapSsrProps(router.asPath, pageProps);
    const { dehydratedState, isStaticRoute, isWidget } = ssrProps;

    const showConsent = useWireframeConsentBannerVisibleListener(ssrProps.cookiesMapped, ssrProps.okResponse);

    const showHiddenMenu = pageProps.isInternalIp && !pageProps.isHiddenMenuDisabled;

    // TODO: Remove in a couple of weeks. Added: 20.12.2024
    React.useEffect(() => {
        try {
            unregisterServiceWorker();
        } catch (error) {
            logger.error(`Unregister Service Worker Error: ${JSON.stringify(error)}`);
        }
    }, []);

    if (isWidget) {
        return (
            <QueryClientProvider client={queryClient}>
                <Hydrate state={dehydratedState}>
                    <AppContextWrapper {...ssrProps}>
                        <HeadDynamic {...ssrProps} />
                        {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                        <Component {...pageProps} />
                    </AppContextWrapper>
                </Hydrate>
            </QueryClientProvider>
        );
    }

    if (isStaticRoute) {
        return (
            <QueryClientProvider client={queryClient}>
                <Hydrate state={dehydratedState}>
                    <ErrorBoundaryWrapper>
                        <AppContextWrapper {...ssrProps}>
                            <HeadDynamic {...ssrProps} />
                            {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                            {ssrProps.isWireframeDisabled ? (
                                <Component {...pageProps} />
                            ) : (
                                <>
                                    <GlobalCss />
                                    <WireframeSwitch
                                        {...ssrProps}
                                        showConsent={showConsent}
                                    >
                                        <Component {...pageProps} />
                                    </WireframeSwitch>
                                </>
                            )}
                        </AppContextWrapper>
                    </ErrorBoundaryWrapper>
                </Hydrate>
            </QueryClientProvider>
        );
    }

    // note: it is important to render this here, because LazyHydrateOn will stop all necessary JS running inside of AppSplashWrapper
    const renderAppSplash = isAppSplashAvailable(ssrProps);

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <ErrorBoundaryWrapper>
                    <AppContextWrapper {...ssrProps}>
                        <HeadDynamic {...ssrProps} />
                        {renderAppSplash && <AppSplash {...ssrProps} />}
                        {showHiddenMenu && <HiddenMenu {...ssrProps} />}
                        <GlobalCss />
                        <WireframeSwitch
                            {...ssrProps}
                            showConsent={showConsent}
                        >
                            <LazyHydrateOn
                                condition={!showConsent}
                                htmlRootTag={'main'}
                            >
                                <Component {...ssrProps} />
                            </LazyHydrateOn>
                        </WireframeSwitch>
                    </AppContextWrapper>
                </ErrorBoundaryWrapper>
            </Hydrate>
        </QueryClientProvider>
    );
}
