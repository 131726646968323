import type { DropdownItem } from '@/core/components/dropdown/dropdown';
import type { ToggleFilterActionType } from '@/features/filter/filter-data/service/filter-toggle-service';
import type { FilterGroupV1, FilterGroupV1Option } from '@/features/filter/filter-data-v1-type';

import SearchInputWithDropdown from '@/core/components/search-input/search-input-with-dropdown/search-input-with-dropdown';
import { Divider } from '@/core/features';
import { scrollToTop } from '@/core/features/scroll/scroll-service';
import color from '@/core/features/styles/color';
import FilterChipDesktop from '@/features/filter/filter-chip/filter-chip-desktop';
import { shouldShowDefaultSelected } from '@/features/filter/filter-data/service/filter-data-service';
import { createTogglePayloadObject } from '@/features/filter/filter-data/service/filter-toggle-service';
import FilterGroupShowMore from '@/features/filter/filter-group/filter-group-show-more/filter-group-show-more';
import { getExpandedFilterGroupOptions } from '@/features/filter/filter-group/filter-group-show-more/filter-group-show-more-service';
import { useExpandedFilterGroup, useFilterGroupSearchState } from '@/features/filter/filter-group/filter-group-state';
import FilterGroupTitleDesktop from '@/features/filter/filter-group-desktop-v1/filter-group-title-desktop/filter-group-title-desktop';

export default function MapAccommodationFilterGroupDesktop(props: {
    componentId: string;
    filterGroup: FilterGroupV1;
    isLastGroup: boolean;
    isLoading: boolean;
    toggleFilter: ToggleFilterActionType;
}) {
    const { componentId, filterGroup } = props;
    const { isSearchOpenInitial, setSearchOpenInitial } = useFilterGroupSearchState(
        props.componentId,
        filterGroup.name,
    );

    const onFilterSelect = (option: FilterGroupV1Option) => {
        props.toggleFilter(createTogglePayloadObject(filterGroup, option));
    };

    const { isExpanded, toggleExpandedFilterGroup } = useExpandedFilterGroup(componentId, filterGroup.name);

    const filterGroupOptions = getExpandedFilterGroupOptions(filterGroup, isExpanded, false);
    const showDefaultSelected = shouldShowDefaultSelected(filterGroup);

    const dropdownItemClick = (item: DropdownItem) => {
        const option = filterGroup.options.find((option) => option.title === item.title);

        if (!option) {
            return;
        }

        onFilterSelect(option);
        if (props.isLoading === false) {
            scrollToTop();
        }
    };

    return (
        <div data-nosnippet={true}>
            <FilterGroupTitleDesktop
                info={filterGroup.info}
                isInNestedGroup={false}
                title={filterGroup.title}
            />

            {filterGroup.searchField && (
                <div className={'padding-bottom-15'}>
                    <SearchInputWithDropdown
                        ctaText={filterGroup.searchField.ctaText}
                        height={40}
                        isDropdownOpenInitial={isSearchOpenInitial}
                        items={filterGroup.options}
                        label={filterGroup.searchField.placeholder}
                        onCtaClick={() => {}}
                        onItemSelect={dropdownItemClick}
                        onToggleDropdown={setSearchOpenInitial}
                        placeholder={filterGroup.searchField.placeholder}
                        searchIconColor={color('nobel')}
                    />
                </div>
            )}

            <div className={'flex-column gap-10'}>
                {filterGroupOptions.map((option) => (
                    <FilterChipDesktop
                        groupType={filterGroup.type}
                        isLoading={props.isLoading}
                        key={option.title}
                        onClick={onFilterSelect}
                        option={option}
                        showDefaultSelected={showDefaultSelected}
                    />
                ))}
            </div>
            {!!filterGroup.maxCount && (
                <FilterGroupShowMore
                    isExpanded={isExpanded}
                    maxCount={filterGroup.maxCount}
                    optionCount={filterGroup.options.length}
                    qaId={'qa-filter-group-show-more-desktop'}
                    toggleExpanded={toggleExpandedFilterGroup}
                />
            )}
            {props.isLastGroup && (
                <Divider
                    className={'width-100'}
                    color={'alto'}
                    marginTop={15}
                />
            )}
        </div>
    );
}
