/* eslint-disable no-inline-styles/no-inline-styles */

import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import color from '@/core/features/styles/color';

export default function CustomerSupportOverlay() {
    const { isDesktopView } = useDeviceoutput();
    return (
        <div
            data-nosnippet={true}
            style={{
                color: color('mineShaft'),
                height: 'auto',
                padding: isDesktopView ? '8px 5px' : 0,
                textAlign: 'center',
                width: isDesktopView ? 350 : '100%',
            }}
        >
            <div
                style={{
                    fontSize: 18,
                    fontWeight: isDesktopView ? 'normal' : 'bold',
                    marginBottom: 20,
                }}
            >
                Sportreisen-Kontakt
            </div>
            <a
                href={'mailto:sportreisen@check24.de'}
                rel={'noreferrer'}
                style={{
                    color: color('lochmara'),
                    display: 'inline-block',
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginBottom: 7,
                }}
                target={'_blank'}
            >
                sportreisen@check24.de
            </a>

            <div className={'customerSupportDescription'}>Stell uns gerne deine Frage.</div>
        </div>
    );
}
