import type { PageComponentType } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentRegistry } from '@/features/a-dynamic-page/components/page-component-registry-type';

import React from 'react';

import {
    AccommodationTilesV1Desktop,
    ActivityAreaTilesV1Desktop,
    ActivitySelectorV1,
    ActivitySpotSwiperV1Desktop,
    ActivityTilesV1Desktop,
    BreadcrumbNavigationV1Desktop,
    CartV1Desktop,
    CheckoutHeaderDesktopV1,
    CheckoutPriceOverviewDesktopV1,
    CheckoutSummaryV1Desktop,
    CityTilesV1Desktop,
    ClimateDiagramsV2Desktop,
    CommunityTilesV1Desktop,
    ContactBarV1Desktop,
    ContinueBookingV1Desktop,
    ContinueSearchV1Desktop,
    CtaBannerDesktopV1,
    CustomerFeedbackV1Desktop,
    FactCheckDesktopV3,
    FilterGroupDesktopV1,
    GalleryV1Desktop,
    HighlightHeaderV1Desktop,
    HighlightSwiperV1Desktop,
    InfoBoxV1Desktop,
    KeyFactsV2Desktop,
    LandmarkTilesV1Desktop,
    MapPreviewV1,
    NavigationBarV1Desktop,
    OnpageSearchBannerDesktopV1,
    OnpageSearchCtaV1Desktop,
    OnpageSearchSeaBannerV1,
    OpeningHoursV1Desktop,
    OtherActivitiesV1Desktop,
    PieChartV1Desktop,
    PlacesV2Desktop,
    QuickFilterV1Desktop,
    RoutesV1Desktop,
    SchoolHeaderV1Desktop,
    SchoolProductSwiperV1Desktop,
    SchoolProductTilesV2Desktop,
    SchoolTilesV2Desktop,
    SeaBannerDesktopV1,
    SkiSlopeDesktopV1,
    SortDesktopV1,
    SpotHeaderDesktopV1,
    SpotResultsDesktopV4,
    SpotSelectorV2Desktop,
    SpotTilesDesktopV3,
    TextBannerDesktopV1,
    ThirdViewBookingInfoDesktop,
    ThirdViewCancellationV2Desktop,
    ThirdViewContactInformationDesktop,
    ThirdViewHeaderDesktop,
    ThirdViewOfferDesktop,
    ThirdViewPaymentInfoV1,
    TippV1Desktop,
    TravelFormV1,
    TrustDesktopV1,
    WindyTileV1Desktop,
    WishlistButtonV1Desktop,
} from '@/features/_index/desktop';
import getFromPageComponentRegistry from '@/features/a-dynamic-page/components/page-component-registry';

export default function getFromPageComponentRegistryDesktop(name: PageComponentType) {
    if (name in registry) {
        return registry[name as keyof typeof registry];
    }
    // fallback to mobile component
    return getFromPageComponentRegistry(name);
}

const registry: PageComponentRegistry = {
    AccommodationTilesV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 106,
            type: 'lazy',
        },
        render: React.memo(AccommodationTilesV1Desktop),
    },
    ActivityAreaTilesV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 170,
            type: 'lazy',
        },
        render: React.memo(ActivityAreaTilesV1Desktop),
    },
    ActivitySelectorV1: {
        lazyConfig: {
            minHeight: 40,
            type: 'eager',
        },
        render: React.memo(ActivitySelectorV1),
    },
    ActivitySpotSwiperV1: {
        lazyConfig: {
            minHeight: 306,
            type: 'eager',
        },
        render: React.memo(ActivitySpotSwiperV1Desktop),
    },
    ActivityTilesV1: {
        lazyConfig: {
            minHeight: 200,
            type: 'eager',
        },
        render: React.memo(ActivityTilesV1Desktop),
    },
    BreadcrumbNavigationV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 14.5,
            type: 'eager',
        },
        render: React.memo(BreadcrumbNavigationV1Desktop),
    },
    CartV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 0,
            type: 'eager',
        },
        render: React.memo(CartV1Desktop),
    },
    CheckoutHeaderDesktopV1: {
        lazyConfig: {
            minHeight: 93,
            type: 'eager',
        },
        render: React.memo(CheckoutHeaderDesktopV1),
    },
    CheckoutPriceOverviewDesktopV1: {
        lazyConfig: {
            minHeight: 151,
            type: 'eager',
        },
        render: React.memo(CheckoutPriceOverviewDesktopV1),
    },
    CheckoutSummaryV1: {
        lazyConfig: {
            minHeight: 560,
            type: 'eager',
        },
        render: React.memo(CheckoutSummaryV1Desktop),
    },
    CityTilesV1: {
        lazyConfig: {
            minHeight: 357,
            type: 'lazy',
        },
        render: React.memo(CityTilesV1Desktop),
    },
    ClimateDiagramsV2: {
        lazyConfig: {
            forceMinHeight: true,
            minHeight: 321,
            type: 'lazy',
        },
        render: React.memo(ClimateDiagramsV2Desktop),
    },
    CommunityTilesV1: {
        lazyConfig: {
            minHeight: 341,
            type: 'lazy',
        },
        render: React.memo(CommunityTilesV1Desktop),
    },
    ContactBarV1: {
        lazyConfig: {
            minHeight: 171,
            type: 'eager',
        },
        render: React.memo(ContactBarV1Desktop),
    },
    ContinueBookingV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 138,
            type: 'lazy',
        },
        render: React.memo(ContinueBookingV1Desktop),
    },
    ContinueSearchV1: {
        lazyConfig: {
            disableSizeUpdate: true,
            minHeight: 138,
            type: 'lazy',
        },
        render: React.memo(ContinueSearchV1Desktop),
    },
    CtaBannerDesktopV1: {
        lazyConfig: {
            minHeight: 460,
            type: 'eager',
        },
        render: React.memo(CtaBannerDesktopV1),
    },
    CustomerFeedbackV1: {
        lazyConfig: {
            minHeight: 141,
            type: 'lazy',
        },
        render: React.memo(CustomerFeedbackV1Desktop),
    },
    FactCheckDesktopV3: {
        lazyConfig: {
            minHeight: 97,
            type: 'eager',
        },
        render: React.memo(FactCheckDesktopV3),
    },
    FilterGroupDesktopV1: {
        lazyConfig: {
            minHeight: 1000,
            type: 'eager',
        },
        render: React.memo(FilterGroupDesktopV1),
    },
    GalleryV1: {
        lazyConfig: {
            minHeight: 460,
            type: 'eager',
        },
        render: React.memo(GalleryV1Desktop),
    },
    HighlightHeaderV1: {
        lazyConfig: {
            disableLazyContainer: true, // sticky and has negative margin top
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(HighlightHeaderV1Desktop),
    },
    HighlightSwiperV1: {
        lazyConfig: {
            customApiLazyHandling: true,
            disableLazyContainer: true,
            minHeight: 0,
            type: 'lazy',
        },
        render: React.memo(HighlightSwiperV1Desktop),
    },
    InfoBoxV1: {
        lazyConfig: {
            minHeight: 68,
            type: 'eager',
        },
        render: React.memo(InfoBoxV1Desktop),
    },
    KeyFactsV2: {
        lazyConfig: {
            minHeight: 60,
            type: 'eager',
        },
        render: React.memo(KeyFactsV2Desktop),
    },
    LandmarkTilesV1: {
        lazyConfig: {
            minHeight: 210,
            type: 'lazy',
        },
        render: React.memo(LandmarkTilesV1Desktop),
    },
    MapPreviewV1: {
        lazyConfig: {
            minHeight: 44,
            type: 'eager',
        },
        render: React.memo(MapPreviewV1),
    },
    NavigationBarV1: {
        lazyConfig: {
            minHeight: 42,
            type: 'eager',
        },
        render: React.memo(NavigationBarV1Desktop),
    },
    OnpageSearchBannerDesktopV1: {
        lazyConfig: {
            minHeight: 460,
            type: 'eager',
        },
        render: React.memo(OnpageSearchBannerDesktopV1),
    },
    OnpageSearchCtaV1: {
        lazyConfig: {
            minHeight: 40,
            type: 'eager',
        },
        render: React.memo(OnpageSearchCtaV1Desktop),
    },
    OnpageSearchSeaBannerV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 500,
            type: 'eager',
        },
        render: React.memo(OnpageSearchSeaBannerV1),
    },
    OpeningHoursV1: {
        lazyConfig: {
            minHeight: 38,
            type: 'eager',
        },
        render: React.memo(OpeningHoursV1Desktop),
    },
    OtherActivitiesV1: {
        lazyConfig: {
            minHeight: 118,
            type: 'lazy',
        },
        render: React.memo(OtherActivitiesV1Desktop),
    },
    PieChartV1: {
        lazyConfig: {
            minHeight: 0,
            type: 'eager',
        },
        render: React.memo(PieChartV1Desktop),
    },
    PlacesV2: {
        lazyConfig: {
            minHeight: 358,
            type: 'lazy',
        },
        render: React.memo(PlacesV2Desktop),
    },
    QuickFilterV1: {
        lazyConfig: {
            disableLazyContainer: true, // might not be visible att all when none are selected
            minHeight: 24,
            type: 'eager',
        },
        render: React.memo(QuickFilterV1Desktop),
    },
    RoutesV1: {
        lazyConfig: {
            minHeight: 403,
            type: 'lazy',
        },
        render: React.memo(RoutesV1Desktop),
    },
    SchoolHeaderV1: {
        lazyConfig: {
            minHeight: 53,
            type: 'eager',
        },
        render: React.memo(SchoolHeaderV1Desktop),
    },
    SchoolProductSwiperV1: {
        lazyConfig: {
            minHeight: 0,
            type: 'lazy',
        },
        render: React.memo(SchoolProductSwiperV1Desktop),
    },
    SchoolProductTilesV2: {
        lazyConfig: {
            minHeight: 236,
            type: 'eager',
        },
        render: React.memo(SchoolProductTilesV2Desktop),
    },
    SchoolTilesV2: {
        lazyConfig: {
            minHeight: 237,
            type: 'lazy',
        },
        render: React.memo(SchoolTilesV2Desktop),
    },
    SeaBannerV1: {
        lazyConfig: {
            minHeight: 500,
            type: 'eager',
        },
        render: React.memo(SeaBannerDesktopV1),
    },
    SkiSlopeDesktopV1: {
        lazyConfig: {
            minHeight: 200,
            type: 'eager',
        },
        render: React.memo(SkiSlopeDesktopV1),
    },
    SortDesktopV1: {
        lazyConfig: {
            minHeight: 35,
            type: 'eager',
        },
        render: React.memo(SortDesktopV1),
    },
    SpotHeaderDesktopV1: {
        lazyConfig: {
            disableLazyContainer: true, // sticky and has negative margin top
            minHeight: 51,
            type: 'eager',
        },
        render: React.memo(SpotHeaderDesktopV1),
    },
    SpotResultsDesktopV4: {
        lazyConfig: {
            disableLazyContainer: true, // loading tiles already reserve space
            minHeight: 750,
            type: 'eager',
        },
        render: React.memo(SpotResultsDesktopV4),
    },
    SpotSelectorV2: {
        lazyConfig: {
            minHeight: 38,
            type: 'eager',
        },
        render: React.memo(SpotSelectorV2Desktop),
    },
    SpotTilesDesktopV3: {
        lazyConfig: {
            minHeight: 444,
            type: 'lazy',
        },
        render: React.memo(SpotTilesDesktopV3),
    },
    TextBannerDesktopV1: {
        lazyConfig: {
            minHeight: 250,
            type: 'eager',
        },
        render: React.memo(TextBannerDesktopV1),
    },
    ThirdViewBookingInfoV1: {
        lazyConfig: {
            minHeight: 157,
            type: 'eager',
        },
        render: React.memo(ThirdViewBookingInfoDesktop),
    },
    ThirdViewCancellationV2: {
        lazyConfig: {
            minHeight: 98,
            type: 'eager',
        },
        render: React.memo(ThirdViewCancellationV2Desktop),
    },
    ThirdViewContactInfoV1: {
        lazyConfig: {
            minHeight: 253,
            type: 'eager',
        },
        render: React.memo(ThirdViewContactInformationDesktop),
    },
    ThirdViewHeaderV1: {
        lazyConfig: {
            minHeight: 146,
            type: 'eager',
        },
        render: React.memo(ThirdViewHeaderDesktop),
    },
    ThirdViewOfferV1: {
        lazyConfig: {
            minHeight: 118,
            type: 'eager',
        },
        render: React.memo(ThirdViewOfferDesktop),
    },
    ThirdViewPaymentInfoV1: {
        lazyConfig: {
            minHeight: 126,
            type: 'eager',
        },
        render: React.memo(ThirdViewPaymentInfoV1),
    },
    TippV1: {
        lazyConfig: {
            minHeight: 55,
            type: 'lazy',
        },
        render: React.memo(TippV1Desktop),
    },
    TravelFormV1: {
        lazyConfig: {
            disableLazyContainer: true,
            minHeight: 90,
            type: 'eager',
        },
        render: React.memo(TravelFormV1),
    },
    TrustDesktopV1: {
        lazyConfig: {
            minHeight: 209,
            type: 'lazy',
        },
        render: React.memo(TrustDesktopV1),
    },
    WindyTileV1: {
        lazyConfig: {
            minHeight: 464,
            type: 'lazy',
        },
        render: React.memo(WindyTileV1Desktop),
    },
    WishlistButtonV1: {
        lazyConfig: {
            minHeight: 40,
            type: 'eager',
        },
        render: React.memo(WishlistButtonV1Desktop),
    },
};
