export const WIREFRAME_LOADED_CUSTOM_EVENT = 'wireframe-loaded-custom-event';
export const WIREFRAME_UNIFIED_LOGIN_EVENT = 'unified-login';
export const WIREFRAME_ULI_LOGIN_LAYER_EVENT = 'uli-login-layer';

export const WIREFRAME_CONSENT_COOKIE_NAME = 'c24consent';

// INFO: missing in actual wireframe
export const WIREFRAME_CONSENT_BANNER_CLASS_MOBILE = 'c24m-cc-visible';
export const WIREFRAME_CONSENT_BANNER_CLASS_DESKTOP = 'c24-cc-visible';
export const WIREFRAME_SEARCH_OPEN_CLASS_MOBILE = 'c24m-search-open';

export const WIREFRAME_HEADER_HEIGHT = 42;
