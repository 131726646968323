import type { HistoryChangeContext } from '@/core/features/router/router-events';

import React from 'react';

import debounce from 'lodash.debounce';

import { IS_SERVER } from '@/constants/env';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { isUnhandledInternalLink } from '@/core/features/link/unhandled-internal-link-service';
import { getHistoryStateProp, historySessionStorage } from '@/core/features/router/history';
import { useRouterEvents } from '@/core/features/router/router-events';
import {
    logScrollRestorationEvent,
    restorePageScrollPosition,
    savePageScrollPosition,
    setScrollRestoration,
} from '@/core/features/scroll/page-scroll-restoration/scroll-history';
import useHasInteractedRef from '@/core/features/scroll/use-has-interacted-ref';

export default function usePageScrollRestoration() {
    const isPageMounted = React.useRef(false);
    const [clientUrl] = useClientUrl();
    const routerEvents = useRouterEvents();
    const hasInteractedRef = useHasInteractedRef(IS_SERVER ? null : window);

    const onRouteChangeStart = React.useCallback(() => {
        if (!isPageMounted.current) {
            logScrollRestorationEvent('Page is not yet mounted (onRouteChangeStart)', clientUrl);
            // do not handle the first route change of the page
            // only handle route transitions triggered by the user within the SPA
            return;
        }
        logScrollRestorationEvent('onRouteChangeStart', clientUrl);

        savePageScrollPosition();
    }, [clientUrl]);

    const onRouteChangeComplete = React.useCallback(() => {
        setTimeout(() => {
            setScrollRestoration('manual');
        }, 1000); // wait some time in case of browser backs from external pages, otherwise the scroll restoration "auto" will not work
        logScrollRestorationEvent('onRouteChangeComplete', clientUrl, history.state);
        restorePageScrollPosition(clientUrl, { hasInteractedRef });
    }, [clientUrl, hasInteractedRef]);

    // Case: selecting a filter should not scroll page back to top. So in case of a history change with type historyReplace we update the url of the current history entry so the next scroll restoration will use the current scoll position
    const onHistoryChange = React.useCallback(
        ({ disableScrollHistory, historyType, navigateUrl }: HistoryChangeContext) => {
            if (historyType === 'historyReplace' && !disableScrollHistory) {
                logScrollRestorationEvent('onHistoryChange', navigateUrl);
                savePageScrollPosition(navigateUrl);
            }
        },
        [],
    );

    const onLinkout = React.useCallback(() => {
        logScrollRestorationEvent('onLinkout');
        savePageScrollPosition();

        historySessionStorage.set(history.state);

        // WARNING: Keep in mind that this behaviour only works properly with a production build. In dev, this does not work all the time. Test accordingly.
        setScrollRestoration('auto');
    }, []);

    /**
     * Effect: Set hasTouchedRef to true on touchstart or wheel event
     * to prevent scroll restoration once the user interacts with the page
     */
    React.useEffect(() => {
        const onRouteChangeStart = () => {
            hasInteractedRef.current = false;
        };
        const touchHandler = debounce(() => {
            if (hasInteractedRef.current) {
                return;
            }
            hasInteractedRef.current = true;
        }, 20);

        window.addEventListener('touchstart', touchHandler);
        window.addEventListener('wheel', touchHandler);
        routerEvents.on('routeChangeStart', onRouteChangeStart);

        return () => {
            window.removeEventListener('scroll', touchHandler);
            window.removeEventListener('wheel', touchHandler);
            routerEvents.off('routeChangeStart', onRouteChangeStart);
        };
    }, [routerEvents, hasInteractedRef]);

    /**
     * Effect: handle first render when bfcache does not trigger (sometimes in chrome)
     */
    React.useEffect(() => {
        if (isPageMounted.current) {
            return;
        }
        const url = getHistoryStateProp<string>('url');
        if (!url) {
            return;
        }
        logScrollRestorationEvent('initial render', url, history.scrollRestoration);

        restorePageScrollPosition(url, { hasInteractedRef });
        restorePageScrollPosition(clientUrl, { hasInteractedRef });
        isPageMounted.current = true;
    }, [clientUrl, hasInteractedRef]);

    /**
     * Effect: add route change event listeners
     */
    React.useEffect(() => {
        logScrollRestorationEvent('init', clientUrl);
        routerEvents.on('linkout', onLinkout);
        routerEvents.on('routeChangeStart', onRouteChangeStart);
        routerEvents.on('routeChangeComplete', onRouteChangeComplete);
        routerEvents.on('historyChange', onHistoryChange);

        return () => {
            logScrollRestorationEvent('cleanup', clientUrl);
            routerEvents.off('linkout', onLinkout);
            routerEvents.off('routeChangeStart', onRouteChangeStart);
            routerEvents.off('routeChangeComplete', onRouteChangeComplete);
            routerEvents.off('historyChange', onHistoryChange);
        };
    }, [clientUrl, onHistoryChange, onLinkout, onRouteChangeComplete, onRouteChangeStart, routerEvents]);

    /**
     * Effect: register routing link clicks that are not a Link Component
     */
    React.useEffect(() => {
        const clickListener = (event: MouseEvent) => {
            if (!isUnhandledInternalLink(event.target)) {
                return;
            }
            onLinkout();
        };

        window.addEventListener('click', clickListener);

        return () => {
            window.removeEventListener('click', clickListener);
        };
    }, [onLinkout]);

    const setHasInteractedRef = (state: boolean) => {
        hasInteractedRef.current = state;
    };

    return { setHasInteractedRef };
}
