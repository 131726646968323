import { IS_SERVER } from '@/constants/env';
import { getLogPrefix } from '@/core/features/logger/logger';
import { historyReplaceState } from '@/core/features/router/history';
import { getUrlPathWithSearch } from '@/core/utils/url';

const LOGS_ENABLED = false;

export const logScrollRestorationEvent =
    !LOGS_ENABLED || IS_SERVER
        ? () => {}
        : (...message: unknown[]) => {
              // eslint-disable-next-line no-console
              return console.info(...getLogPrefix(), 'Restoration:', ...message);
          };

export type ScrollHistoryState = {
    scrollY: number;
    url: string;
};

/**
 * some state has to be persisted in memory as history state is not sufficient
 * Example: In order to restore the max scroll height of the previous page
 */
type ScrollPositionStorage = Record<
    string, // url path with search
    {
        pageComponentIndex: number;
        pageMaxHeight: number;
    }
>;

const scrollPositionStorage: ScrollPositionStorage = {};

export const setScrollRestoration = (type: ScrollRestoration) => {
    if (!('scrollRestoration' in window.history)) {
        return;
    }

    if (window.history.scrollRestoration === type) {
        return;
    }

    logScrollRestorationEvent('setScrollRestoration', type);

    // eslint-disable-next-line fp/no-mutation
    window.history.scrollRestoration = type;
};

const scrollToTop = () => {
    const scrollY = window.scrollY;

    if (scrollY === 0) {
        return;
    }

    logScrollRestorationEvent('Scroll back to 0');

    window.scrollTo({ top: 0 });
};

export const savePageScrollPosition = (navigateUrl = location.href) => {
    logScrollRestorationEvent('savePageScrollPosition', history.state);
    const url = getUrlPathWithSearch(navigateUrl);
    const scrollY = window.scrollY;

    const scrollHistoryState: ScrollHistoryState = {
        scrollY,
        url,
    };

    logScrollRestorationEvent('updateScrollHistory', scrollHistoryState);

    historyReplaceState({ scrollHistoryState });
};

export const restorePageScrollPosition = (
    url: string,
    { hasInteractedRef }: { hasInteractedRef: React.MutableRefObject<boolean> },
) => {
    if (!('scrollRestoration' in window.history)) {
        return;
    }

    if (window.history.scrollRestoration !== 'manual') {
        return;
    }
    if (url.includes('buchung') || url.includes('checkout')) {
        scrollToTop();
        return;
    }
    const path = getUrlPathWithSearch(url);
    const isMaxBodyScrollHeightSet = setMaxScrollHeightOnBody(path);
    const store = history.state.scrollHistoryState as ScrollHistoryState | undefined;

    if (!store || path !== store.url || !store.scrollY) {
        logScrollRestorationEvent('No scroll position stored for', path, history.state.scrollHistoryState);
        scrollToTop();
        return;
    }

    const scrollY = window.scrollY;

    logScrollRestorationEvent('scrolling to', path, store.scrollY, 'current:', scrollY);

    setTimeout(() => {
        window.scrollTo({ top: store.scrollY });
    }, 0);

    // make sure scroll position is restored also for less performant devices unless user interacts
    let retryScrollTimeout: NodeJS.Timeout | null = null;
    const retryUrl = location.href;
    const retryScrollHandler = ({ retryCount = 0 } = {}) => {
        if (retryUrl !== location.href) {
            logScrollRestorationEvent('aborting scroll retry since url changed from', retryUrl, 'to', location.href);

            // abort retry on url changed
            return;
        }

        if (retryCount > 3) {
            return;
        }
        if (retryScrollTimeout !== null && hasInteractedRef?.current) {
            clearTimeout(retryScrollTimeout);
            return;
        }
        const scrollY = window.scrollY;
        if (scrollY !== store.scrollY) {
            logScrollRestorationEvent('retry scroll', path, store.scrollY, 'current:', scrollY);

            window.scrollTo({ top: store.scrollY });
        } else {
            return;
        }

        // eslint-disable-next-line fp/no-mutation
        retryScrollTimeout = setTimeout(() => {
            retryScrollHandler({ retryCount: retryCount + 1 });
        }, 1000);
    };

    setTimeout(() => {
        retryScrollHandler();
    }, 100);

    if (isMaxBodyScrollHeightSet) {
        /* 2600ms - accumulated delay of all previously set timeouts to ensure that the body minHeight is not reset too early. */
        unsetMaxScrollHeightOnBody(2600);
    }
};

const setMaxScrollHeightOnBody = (path: string): boolean => {
    const store = scrollPositionStorage[path];
    const pageMaxHeight = store?.pageMaxHeight ?? 0;

    if (!pageMaxHeight) {
        return false;
    }

    if (document.querySelector('body')?.style.overflow === 'hidden') {
        return false;
    }

    logScrollRestorationEvent('Set <body> maxScroll', JSON.stringify(store));

    // we add 2000px as buffer, also so that the footer is not visible during transition
    document.querySelector('body')?.setAttribute('style', `min-height: ${pageMaxHeight + 2000}px`);

    return true;
};

const unsetMaxScrollHeightOnBody = (delay: number) => {
    const timeout = setTimeout(() => {
        logScrollRestorationEvent('Unset <body> maxScroll');
        // timeout is needed here as rendering of previous page might take some time
        document.querySelector('body')?.removeAttribute('style');
    }, delay);

    window.addEventListener(
        'popstate',
        () => {
            clearTimeout(timeout);
        },
        { once: true },
    );
};

export const savePageComponentIndex = (path: string, index: number) => {
    const store = scrollPositionStorage[path];

    if (!store) {
        return;
    }
    if (store.pageComponentIndex && store.pageComponentIndex > index) {
        return;
    }

    logScrollRestorationEvent('savePageComponentIndex', JSON.stringify(store));

    // eslint-disable-next-line fp/no-mutation
    scrollPositionStorage[path] = { ...store, pageComponentIndex: index };
};

export const getPageComponentIndex = (path: string): null | number => {
    const store = scrollPositionStorage[path];
    logScrollRestorationEvent('getPageComponentIndex', JSON.stringify(store));
    return store?.pageComponentIndex ?? null;
};
