/* eslint-disable @next/next/no-img-element */
import type {
    GeneralTrackingPixel,
    TrackingMeta,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { CookiesMapped, Deviceoutput } from '@/core/features/cookies/cookies-service';

import React from 'react';

import { IS_SERVER, NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME } from '@/constants/env';
import { useCookiesMapped } from '@/core/features/app/app-atoms';
import { getDeviceInfo, getDeviceOSMapped } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import FeatureFlagStore from '@/core/features/store/feature-flags-store';

const featureFlagStore = FeatureFlagStore();

type GeneralTrackingParams = {
    additionalSearchParams?: Record<string, string>;
    cookiesMapped: CookiesMapped;
    deviceoutput: Deviceoutput;
    overrides?: {
        actionId?: string;
        areaId?: string;
    };
    trackingMeta: TrackingMeta;
};

// uses trackingMeta.generalTrackingPixels to generate urls
const getGeneralTrackingPixelUrls = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    overrides,
    trackingMeta,
}: GeneralTrackingParams): string[] => {
    return trackingMeta.generalTrackingPixels.reduce<string[]>((acc, generalTrackingPixel) => {
        const url = getGeneralTrackingUrl({
            additionalSearchParams,
            cookiesMapped,
            deviceoutput,
            generalTrackingPixel,
            overrides,
            trackingMeta,
        });

        if (url) {
            acc.push(url);
        }
        return acc;
    }, []);
};

const getGeneralTrackingUrl = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    generalTrackingPixel,
    overrides,
    trackingMeta,
}: GeneralTrackingParams & { generalTrackingPixel?: GeneralTrackingPixel }): null | string => {
    const { indiSession } = cookiesMapped;
    if (!NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME) {
        logger.error('NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME is missing in env Variables');
        return null;
    }

    if (!indiSession) {
        logger.error('indiSession is missing in getGeneralTrackingUrl');
        return null;
    }

    const {
        actionId: actionIdMeta,
        areaId: areaIdMeta,
        pid,
        siteId,
    } = generalTrackingPixel ? generalTrackingPixel : trackingMeta;

    const areaId = overrides?.areaId ?? areaIdMeta;
    const actionId = overrides?.actionId ?? actionIdMeta;

    // in case of request error we fallback the areaId with dynamicPageMetaFallback to empty string
    // in that case we should not track the page
    if (areaId === '') {
        return null;
    }

    const product = NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME;

    const { deviceOS } = getDeviceInfo(cookiesMapped.deviceinfo, indiSession);
    const deviceOsMapped = getDeviceOSMapped(deviceOS);

    const url = new URL(
        `https://asset42.check24.de/gif/site_id/${siteId}/pid/${pid}/area_id/${areaId}/product/${product}/sid/${indiSession}/action_id/${actionId}/deviceoutput/${deviceoutput}/os/${deviceOsMapped}/image.gif`,
    );

    if (additionalSearchParams) {
        Object.entries(additionalSearchParams).forEach(([key, value]) => {
            url.searchParams.set(key, value);
        });
    }

    return url.toString();
};

export const fireGeneralTrackingPixels = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    overrides,
    trackingMeta,
}: GeneralTrackingParams): void => {
    const urls = getGeneralTrackingPixelUrls({
        additionalSearchParams,
        cookiesMapped,
        deviceoutput,
        overrides,
        trackingMeta,
    });

    if (!IS_SERVER) {
        featureFlagStore.log('trackingLogs', 'Tracking Log: GT Event, URLs:', urls);
    }

    urls.map((url) => {
        fetch(url, { mode: 'no-cors' }).catch((_error) => {
            // console.info(`Error while firing general tracking, error: ${_error}`);
        });
    });
};

// https://c24-indi.atlassian.net/browse/INDI-3416
export const fireGeneralTracking = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    overrides,
    trackingMeta,
}: GeneralTrackingParams): void => {
    const url = getGeneralTrackingUrl({
        additionalSearchParams,
        cookiesMapped,
        deviceoutput,
        overrides,
        trackingMeta,
    });

    if (!url) {
        return;
    }

    if (!IS_SERVER) {
        featureFlagStore.log('trackingLogs', `Tracking Log: GT Event, URL: ${url}`);
    }

    fetch(url, { mode: 'no-cors' }).catch((_error) => {
        // console.info(`Error while firing general tracking, error: ${_error}`);
    });
};

/**
 * https://c24-indi.atlassian.net/browse/INDI-693
 * Example: https://asset42.check24.de/gif/site_id/168/pid/ch24_indi_hp_dti/area_id/s0/product/indi/sid/69584682545/action_id/1941/deviceoutput/mobile/image.gif
 */
export const C24GeneralTrackingBodyPixel = ({
    additionalSearchParams,
    deviceoutput,
    trackingMeta,
}: {
    additionalSearchParams?: Record<string, string>;
    deviceoutput: Deviceoutput;
    trackingMeta: TrackingMeta;
}) => {
    const prevTrackedUrl = React.useRef<string[]>([]);

    const shouldRender = useClientOnlyRender();
    const cookiesMapped = useCookiesMapped();

    // tracking pixel should not render server-side as it is sessionStorage dependent
    if (!shouldRender) {
        return null;
    }

    if (trackingMeta.manualGeneralTracking) {
        return null;
    }

    const urls = getGeneralTrackingPixelUrls({
        additionalSearchParams,
        cookiesMapped,
        deviceoutput,
        trackingMeta,
    });

    if (prevTrackedUrl.current.some((url) => !urls.includes(url))) {
        prevTrackedUrl.current = urls;

        if (!IS_SERVER) {
            featureFlagStore.log('trackingLogs', `Tracking Log: GT Event, URLs: ${urls}`);
        }
    }

    return (
        <>
            {urls.map((url) => (
                <img
                    alt={''}
                    className={'absolute'}
                    id={'asset42'}
                    key={url}
                    src={url}
                />
            ))}
        </>
    );
};
