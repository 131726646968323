import type { DynamicPageData } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import type { UseQueryResult } from '@/core/features/react-query/react-query-service';

import React from 'react';

import { IS_SERVER } from '@/constants/env';
import { CLIENT_CACHE_REFETCH_HEADER } from '@/constants/request-headers';
import { cacheRefetchUrlStateAtom } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query-cache-handler';
import useDynamicPageRedirect from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-redirect';
import useDynamicPageUrlRewrite from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-url-rewrite';
import {
    getDynamicPageClientSide,
    getDynamicPageQueryKey,
} from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useClientUrl, useSsrUrl } from '@/core/features/app/app-atoms';
import { useQuery, useQueryClient } from '@/core/features/react-query/react-query-service';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import { DEFAULT_QUERY_STALE_TIME } from '@/core/features/request/request-constants';
import { atom, useAtomValue, useHydrateAtoms, useSetAtom } from '@/core/features/store/atom-store';
import { buildUrlWithSearch, getURL, getUrlPathWithSearch, urlSearchParamsToObject } from '@/core/utils/url';

type DynamicPageQueryData = {
    data: DynamicPageData | null;
    error: Error | null;
};

type DynamicPageQueryLoading = {
    isFetching: boolean;
    isPending: boolean;
};

export type DynamicPageQuery = UseQueryResult<DynamicPageData, Error>;

const dynamicPageQueryLoadingAtom = atom<DynamicPageQueryLoading>({
    isFetching: false,
    isPending: false,
});

const dynamicPageQueryDataAtom = atom<DynamicPageQueryData>({
    data: null,
    error: null,
});

export const useDynamicPageQueryFetching = () => useAtomValue(dynamicPageQueryLoadingAtom);
export const useDynamicPageQueryData = () => useAtomValue(dynamicPageQueryDataAtom);

export const useDynamicPageQueryKey = () => {
    const ssrUrl = useSsrUrl();
    const [clientUrl] = useClientUrl();

    const parsedClientUrl = getURL(clientUrl);

    const currentUrl = IS_SERVER ? ssrUrl : getUrlPathWithSearch(parsedClientUrl);

    const queryKey = getDynamicPageQueryKey(currentUrl);
    return queryKey;
};

// warning: this hook should only be used once to avoid multiple dependencies (e.g. useDynamicPageUrlRewrite, useDynamicPageRedirect etc.)
// use jotai state hooks instead, see above
export const useHydrateDynamicPageQueryData = (options?: { refetchOnClient?: true }): void => {
    const setDynamicPageQueryData = useSetAtom(dynamicPageQueryDataAtom);
    const setDynamicPageQueryLoading = useSetAtom(dynamicPageQueryLoadingAtom);
    const [clientUrl, setClientUrl] = useClientUrl();
    const queryClient = useQueryClient();
    const isClient = useClientOnlyRender();

    const parsedClientUrl = getURL(clientUrl);

    const url = IS_SERVER
        ? ''
        : buildUrlWithSearch(
              parsedClientUrl.pathname,
              urlSearchParamsToObject(parsedClientUrl.searchParams),
          ).toString();

    const isCacheRefetch = useAtomValue(cacheRefetchUrlStateAtom) === clientUrl;

    const headers = isCacheRefetch ? { ...CLIENT_CACHE_REFETCH_HEADER } : undefined;

    const dynamicPageQueryKey = useDynamicPageQueryKey();
    const clientRefetchQueryKey = options?.refetchOnClient && isClient ? ['clientRefetch'] : [];

    const query: DynamicPageQuery = useQuery<DynamicPageData, Error>(
        [...dynamicPageQueryKey, ...clientRefetchQueryKey],
        () => getDynamicPageClientSide({ headers, url }),
        {
            staleTime: DEFAULT_QUERY_STALE_TIME,
        },
    );

    useDynamicPageUrlRewrite(clientUrl, query, queryClient, setClientUrl);
    useDynamicPageRedirect(query.error);

    const dynamicPageQueryLoading: DynamicPageQueryLoading = React.useMemo(
        () => ({
            isFetching: query.isFetching,
            isLoading: query.isLoading,
            isPending: query.isPending,
        }),
        [query.isFetching, query.isLoading, query.isPending],
    );

    const dynamicPageQueryData: DynamicPageQueryData = React.useMemo(
        () => ({
            data: query.data ?? null,
            error: query.error,
        }),
        [query.data, query.error],
    );

    useHydrateAtoms([
        [dynamicPageQueryDataAtom, dynamicPageQueryData], //
        [dynamicPageQueryLoadingAtom, dynamicPageQueryLoading],
    ]);

    React.useEffect(() => {
        setDynamicPageQueryLoading(dynamicPageQueryLoading);
    }, [dynamicPageQueryLoading, setDynamicPageQueryLoading]);
    React.useEffect(() => {
        if (dynamicPageQueryLoading.isFetching) {
            return;
        }
        setDynamicPageQueryData(dynamicPageQueryData);
    }, [dynamicPageQueryData, dynamicPageQueryLoading.isFetching, setDynamicPageQueryData]);
};
